import { useCallback, useContext, useEffect, useState } from 'react'
import { ApiContext } from '../../containers/ApiContainer'

export const useEventHandlerById = (cb) => {
  const [state, setState] = useState()

  const handler = useCallback(
    (e) => {
      const { id } = e.currentTarget
      setState(id)
      if (cb) cb(e)
    },
    [cb]
  )

  return [state, handler, setState]
}

export const useSetActiveConversation = (uuid, conversation) => {
  const { setActiveConversationUuid, setActiveConversation } = useContext(ApiContext)

  useEffect(() => {
    setActiveConversation(conversation)
    setActiveConversationUuid(uuid)
    return () => {
      setActiveConversationUuid('')
      setActiveConversation(undefined)
    }
  }, [uuid, setActiveConversation, setActiveConversationUuid, conversation])
}

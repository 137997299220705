import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import GridContainerBlock from '../../components/GridContainerBlock'
import ContentBlock from '../../components/ContentBlock'
import PageBackTitle from '../../components/PageBackTitle'
import styles from './MessengerPage.module.scss'
import Counter from '../../components/Counter'
import { ApiContext } from '../../containers/ApiContainer'
import { HOME_PAGE } from '../../constants'
import { useQueryParams } from '../../hooks/useQueryParams'
import SpinnerWrapper from '../../components/SpinnerWrapper'
import { showPleaseLogInError } from '../../utils/showPleaseLogInError'
import Sell from './Sell'
import Buy from './Buy'

export const SELL = 'sell'
export const BUY = 'buy'

const MessengerPage = () => {
  const history = useHistory()

  const {
    messages,
    user,
    isLoading,
    getUserStats,
    loadProductsBuying,
    loadProductsSelling,
  } = useContext(ApiContext)
  // console.log('--------------- messages ---------------', messages)
  const { buyingCount, sellingCount } = messages
  const { productUuid, tab } = useQueryParams()
  const [activeTab, setActiveTab] = useState(() => tab || BUY)

  useEffect(() => {
    if (user) {
      loadProductsBuying(!!productUuid)
      loadProductsSelling(!!productUuid)
      getUserStats()
    } else {
      showPleaseLogInError()
      history.push(HOME_PAGE)
    }
  }, [user, productUuid, loadProductsBuying, loadProductsSelling, history, getUserStats])

  const handleTabClick = useCallback((e) => {
    const { tab } = e.currentTarget.dataset
    setActiveTab(tab)
  }, [])

  const Tabs = () => (
    <div className={styles.tabs}>
      <div
        onClick={handleTabClick}
        data-tab={SELL}
        className={`${styles.tab} ${activeTab === SELL ? styles.activeTab : ''}`}
      >
        <span>Продажа</span> <Counter count={sellingCount} gray />
      </div>
      <div
        onClick={handleTabClick}
        data-tab={BUY}
        className={`${styles.tab} ${activeTab === BUY ? styles.activeTab : ''}`}
      >
        <span>Покупка</span> <Counter count={buyingCount} />
      </div>
    </div>
  )

  return (
    <GridContainerBlock className={styles.height100}>
      <ContentBlock noMargin>
        <PageBackTitle className={styles.pageTitle} title={<Tabs />} />
        <SpinnerWrapper isLoading={isLoading} className={styles.containerHeight}>
          {activeTab === SELL ? <Sell /> : <Buy />}
        </SpinnerWrapper>
      </ContentBlock>
    </GridContainerBlock>
  )
}

export default MessengerPage

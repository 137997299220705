import React from 'react'
import styles from './TextButton.module.scss'
import commonStyles from '../../styles/common.module.scss'

/**
 * @param children {jsx}
 * @param color {"Red"|"Blue"|"DarkBlue"|"Green"|"Orange"}
 * @param className {string}
 * @param props {any}
 */
const TextButton = ({ children, color, className = '', ...props }) => {
  return (
    <div className={`${styles.btn} ${commonStyles[`color${color}`]} ${className}`} {...props}>
      {children}
    </div>
  )
}

export default TextButton

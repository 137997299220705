import React, { useContext } from 'react'
import { SELL } from './MessengerPage'
import { ApiContext } from '../../containers/ApiContainer'

const BuyerInfo = ({ tab }) => {
  const {
    messages: { activeProduct, activeConversationUuid },
  } = useContext(ApiContext)

  if (tab !== SELL) return null

  const { buyer = {} } =
    activeProduct?.conversations?.find(({ uuid }) => uuid === activeConversationUuid) || {}

  return (
    <div>
      Покупатель: {buyer.firstname} {buyer.address?.city ? `(${buyer.address?.city})` : ''}
    </div>
  )
}

export default BuyerInfo

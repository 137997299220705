import React, { useCallback, useReducer } from 'react'
import { api } from '../api'
import { destroySession, getUser, removeUser, setAccessToken, setUser } from '../utils/session'
import { createQueryStringFromObject } from '../utils/createQueryStringFromObject'
import { withTryCatch } from '../utils/withTryCatch'
import { BUY, SELL } from '../pages/MessengerPage'

const defaultState = {
  homeProducts: { products: [], summary: {} },
  isLoading: false,
  advCard: {
    seller: {},
    categories: [],
    delivery: {},
    condition: {},
    similarProducts: [],
  },
  wishList: {
    loaded: false,
    products: [],
    summary: undefined,
    count: 0,
  },
  adverts: {
    loaded: false,
    products: [],
    summary: undefined,
  },
  currentProduct: null,
  pendingProducts: [],
  orders: {
    loaded: false,
    orders: [],
    summary: undefined,
  },
  messages: {
    activeProduct: undefined,
    activeConversation: undefined, // TODO: think
    activeConversationUuid: undefined,
    buying: [],
    buyingCount: 0,
    sellingUuids: [],
    sellingMap: {},
    sellingCount: 0,
    listByUUID: {},
  },
  allProducts: {},
  categories: [],
  manufacturers: [],
  countries: [],
  zones: [],
  deliveries: [],
  conditions: [],
  user: getUser(),
}

const addProductToMap = (state, action) => {
  const allProducts = { ...state.allProducts }
  if (Array.isArray(action.payload)) {
    action.payload.forEach((product) => {
      allProducts[product.uuid] = product
    })
  } else {
    allProducts[action.payload.uuid] = action.payload
  }
  return allProducts
}

const appReducer = (state, action) => {
  switch (action.type) {
    case 'isLoading':
      return { ...state, isLoading: action.payload }
    case 'home': {
      const { isShowMore, data } = action.payload
      const { summary, products } = data || {}
      return {
        ...state,
        homeProducts: {
          products: isShowMore ? [...state.homeProducts.products, ...products] : products,
          summary,
        },
      }
    }
    case 'advCard':
      return {
        ...state,
        allProducts: addProductToMap(state, action),
        advCard: { ...state.advCard, ...action.payload },
      }
    case 'similarProducts': {
      return {
        ...state,
        advCard: { ...state.advCard, similarProducts: action.payload?.products || [] },
      }
    }
    case 'user':
      return { ...state, user: action.payload }
    case 'wishList':
      return {
        ...state,
        allProducts: addProductToMap(state, { payload: action.payload.products }),
        wishList: {
          ...state.wishList,
          loaded: true,
          count: action.payload.summary.total,
          summary: action.payload.summary,
          products: action.payload.products,
        },
      }
    case 'wishListCount':
      return { ...state, wishList: { ...state.wishList, count: action.payload } }
    case 'adverts':
      return {
        ...state,
        allProducts: addProductToMap(state, { payload: action.payload.products }),
        adverts: { ...action.payload, loaded: true },
      }
    case 'currentProduct':
      return { ...state, currentProduct: action.payload }
    case 'pendingProducts':
      return { ...state, pendingProducts: action.payload }
    case 'orders':
      return {
        ...state,
        allProducts: addProductToMap(state, {
          payload: action.payload.orders.map(({ product }) => product),
        }),
        orders: { ...action.payload, loaded: true },
      }
    case 'buyingCount':
      return { ...state, messages: { ...state.messages, buyingCount: action.payload || 0 } }
    case 'sellingCount':
      return {
        ...state,
        messages: {
          ...state.messages,
          sellingCount: action.payload || 0,
        },
      }
    case 'buyingProducts': {
      let buying = action.payload
      if (action.isCombine) {
        const combinedArr = [...state.messages.buying, ...action.payload] // TODO: think about it. Does it work properly?
        const filteredObj = combinedArr.reduce(
          (obj, product) => ({ ...obj, [product.uuid]: product }),
          {}
        )
        buying = Object.values(filteredObj)
      }
      return { ...state, messages: { ...state.messages, buying } }
    }
    case 'sellingProducts': {
      let sellingUuids = action.payload.map(({ uuid }) => uuid)
      let sellingMap = action.payload.reduce(
        (obj, product) => ({
          ...obj,
          [product.uuid]: {
            ...product,
            totalUnReadMsgs: product.conversations.reduce(
              (sum, { unreadMessagesCount = 0 }) => sum + unreadMessagesCount,
              0
            ),
          },
        }),
        {}
      )

      if (action.isCombine) {
        sellingUuids = [...new Set([...state.messages.sellingUuids, ...sellingUuids])]
        sellingMap = { ...state.messages.sellingMap, ...sellingMap }
      }

      return {
        ...state,
        messages: {
          ...state.messages,
          sellingUuids,
          sellingMap,
        },
      }
    }
    case 'buying2': {
      const product = { ...action.payload.product, conversation: action.payload }
      const productIsPresent = state.messages.buying.some(({ uuid }) => uuid === product.uuid)
      let buying = state.messages.buying
      if (!productIsPresent) {
        buying = [product, ...buying]
      }
      return {
        ...state,
        messages: {
          ...state.messages,
          buying,
          activeProduct: product,
          activeConversationUuid: action.payload.uuid,
          activeConversation: action.payload,
        },
      }
    }
    case 'selling2': {
      const product = { ...action.payload.product, conversations: [action.payload] }
      const productIsPresent = state.messages.sellingUuids.some((uuid) => uuid === product.uuid)
      let sellingUuids = state.messages.sellingUuids
      let sellingMap = state.messages.sellingMap

      if (!productIsPresent) {
        sellingUuids = [product.uuid, ...sellingUuids]
        sellingMap[product.uuid] = product
      }

      return {
        ...state,
        messages: {
          ...state.messages,
          sellingUuids,
          sellingMap,
          activeProduct: product,
          activeConversationUuid: action.payload.uuid,
          activeConversation: action.payload,
        },
      }
    }
    case 'categories':
      return { ...state, categories: action.payload }
    case 'deliveries':
      return { ...state, deliveries: action.payload }
    case 'conditions':
      return { ...state, conditions: action.payload }
    case 'manufacturers':
      return { ...state, manufacturers: action.payload }
    case 'countries':
      return { ...state, countries: action.payload }
    case 'zones': {
      const zones = [state.user.address?.zone, ...action.payload]
      if (!state.user.address?.zone) zones.shift()
      return {
        ...state,
        zones,
      }
    }
    case 'deleteBuyConversation': {
      const buying = state.messages.buying.filter(
        ({ conversation }) => conversation.uuid !== action.payload
      )
      return {
        ...state,
        messages: {
          ...state.messages,
          activeConversationUuid: buying[0]?.conversation?.uuid,
          buying,
        },
      }
    }
    case 'deleteSELLConversation': {
      const conversations = state.messages.activeProduct.conversations.filter(
        ({ uuid }) => uuid !== action.payload
      )
      const activeConversationUuid = conversations[0]?.uuid
      return {
        ...state,
        messages: {
          ...state.messages,
          activeConversationUuid,
          activeProduct: {
            ...state.messages.activeProduct,
            conversations,
          },
          sellingMap: {
            ...state.messages.sellingMap,
            [state.messages.activeProduct.uuid]: {
              ...state.messages.sellingMap[state.messages.activeProduct.uuid],
              conversations: state.messages.sellingMap[
                state.messages.activeProduct.uuid
              ].conversations.filter(({ uuid }) => uuid !== action.payload),
            },
          },
        },
      }
    }
    case 'messages':
      return {
        ...state,
        messages: {
          ...state.messages,
          listByUUID: {
            ...state.messages.listByUUID,
            [action.payload.uuid]: action.payload.data.messages,
          },
        },
      }
    case 'searchBuyConversation': {
      const text = action.payload.toLowerCase()
      const buyingOriginal = state.messages.buyingOriginal
        ? [...state.messages.buyingOriginal]
        : [...state.messages.buying]
      const buying = buyingOriginal.filter(({ name, seller: { firstname, address: { city } } }) =>
        `${name}${firstname}${city}`.toLowerCase().includes(text)
      )
      return {
        ...state,
        messages: {
          ...state.messages,
          buying,
          buyingOriginal,
        },
      }
    }
    case 'searchSellConversation': {
      const text = action.payload.toLowerCase()

      const sellingMapOriginal = state.messages.sellingMapOriginal
        ? { ...state.messages.sellingMapOriginal }
        : { ...state.messages.sellingMap }

      // here we filter products whose names include the search text
      const filteredSellingMapByProductName = Object.values(sellingMapOriginal)
        .filter(({ name }) => name.toLowerCase().includes(text))
        .reduce((obj, product) => ({ ...obj, [product.uuid]: product }), {})

      // here we are filtering conversation for each product
      const filteredSellingMapByConversationBuyerInfo = Object.values(sellingMapOriginal)
        .map((product) => ({
          ...sellingMapOriginal[product.uuid],
          conversations: product.conversations.filter(({ buyer: { firstname, lastname } }) =>
            `${firstname}${lastname}`.toLowerCase().includes(text)
          ),
        }))
        .filter(({ conversations }) => !!conversations.length)
        .reduce((obj, product) => ({ ...obj, [product.uuid]: product }), {})

      // here we merge filtered product by name and by conversations buyer info
      const sellingMap = {
        ...filteredSellingMapByProductName,
        ...filteredSellingMapByConversationBuyerInfo,
      }

      const sellingUuids = Object.keys(sellingMap)

      return {
        ...state,
        messages: {
          ...state.messages,
          sellingMapOriginal,
          sellingUuids,
          sellingMap,
        },
      }
    }
    case 'activeProduct':
      return { ...state, messages: { ...state.messages, activeProduct: action.payload } }
    case 'activeConversationUuid':
      return { ...state, messages: { ...state.messages, activeConversationUuid: action.payload } }
    case 'activeConversation':
      return { ...state, messages: { ...state.messages, activeConversation: action.payload } }
    case 'markMsgRead': {
      const isSelling = action.payload.activeTab === SELL
      const field = isSelling ? 'selling' : 'buying'
      let sellingMap = state.messages.sellingMap
      let buying = state.messages.buying

      if (isSelling) {
        // Remove unread mark from sellingMap conversation
        sellingMap = state.messages.sellingUuids.reduce((obj, uuid) => {
          const product = state.messages.sellingMap[uuid]
          const conversations = product.conversations.map((conversation) => {
            const unreadMessagesCount =
              conversation.uuid === action.payload.activeConversationUuid
                ? 0
                : conversation.unreadMessagesCount
            return { ...conversation, unreadMessagesCount }
          })
          return {
            ...obj,
            [uuid]: {
              ...product,
              conversations,
              totalUnReadMsgs: conversations.reduce(
                (sum, { unreadMessagesCount = 0 }) => sum + unreadMessagesCount,
                0
              ),
            },
          }
        }, {})
      } else {
        // Remove unread mark from buying conversation
        buying = state.messages.buying.map(({ conversation, ...product }) => {
          const unreadMessagesCount =
            conversation.uuid === action.payload.activeConversationUuid
              ? 0
              : conversation.unreadMessagesCount
          return {
            ...product,
            unreadMessagesCount,
          }
        })
      }

      return {
        ...state,
        messages: {
          ...state.messages,
          [`${field}Count`]: state.messages[`${field}Count`] - 1,
          sellingMap,
          buying,
        },
      }
    }
    case 'addMessages':
      const prevMsgs = state.messages.listByUUID[action.payload.uuid] || []
      return {
        ...state,
        messages: {
          ...state.messages,
          listByUUID: {
            ...state.messages.listByUUID,
            [action.payload.uuid]: [...prevMsgs, action.payload.message],
          },
        },
      }
    default:
      return state
  }
}

const API_VERSION = '/v1'

export const ApiContext = React.createContext()

export const ApiContainer = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, defaultState, (state) => state)

  // console.log('STATE ', state)
  const loadHomeProductsList = useCallback(
    withTryCatch(async ({ isShowMore, ...params }) => {
      const queryParams = createQueryStringFromObject(params)
      const { data } = await api(dispatch).get(`${API_VERSION}/products${queryParams}`)
      dispatch({ type: 'home', payload: { isShowMore, data } })
    }),
    []
  )

  const logOut = useCallback(
    withTryCatch(async (uuid) => {
      dispatch({ type: 'user', payload: undefined })
      destroySession()
      removeUser()
    }),
    []
  )

  const loadAdvById = useCallback(
    withTryCatch(async (uuid) => {
      const { data } = await api(dispatch).get(`${API_VERSION}/products/${uuid}`)
      dispatch({ type: 'advCard', payload: data })
    }),
    []
  )

  const getUserStats = useCallback(
    withTryCatch(async () => {
      const { data } = await api(dispatch).get(`${API_VERSION}/users/stats`)
      dispatch({ type: 'wishListCount', payload: data.wishlist })
      dispatch({ type: 'buyingCount', payload: data.unreadMessagesFromSellersCount })
      dispatch({ type: 'sellingCount', payload: data.unreadMessagesFromBuyersCount })
    }),
    []
  )

  const postUser = useCallback(
    withTryCatch(async (body) => {
      const { data, rest } = await api(dispatch).post(`/auth`, body)
      dispatch({ type: 'user', payload: data })
      setAccessToken(rest.jwt)
      setUser(data)
    }),
    []
  )

  const loginUser = useCallback(
    withTryCatch(async (body) => {
      const {
        data,
        rest: { jwt },
      } = await api(dispatch).post(`/auth/login`, body)
      dispatch({ type: 'user', payload: data })
      setAccessToken(jwt)
      setUser(data)
    }),
    []
  )

  const passwordResetRequest = useCallback(
    withTryCatch(async (payload) => {
      const { body, cb } = payload
      await api(dispatch).post(`/auth/password_reset_request`, body)
      if (cb) cb()
    }),
    []
  )

  const passwordResetValidate = useCallback(
    withTryCatch(async (payload) => {
      const { body, cb } = payload
      await api(dispatch).post(`/auth/password_reset_validate`, body)
      if (cb) cb()
    }),
    []
  )

  const passwordReset = useCallback(
    withTryCatch(async (payload) => {
      const { body, cb } = payload
      await api(dispatch).post(`/auth/password_reset`, body)
      if (cb) cb()
    }),
    []
  )

  const loadWishList = useCallback(
    withTryCatch(async () => {
      const { data } = await api(dispatch).get(`${API_VERSION}/products/wishlist`)
      dispatch({ type: 'wishList', payload: data })
    }),
    []
  )

  const addToWishList = useCallback(
    withTryCatch(async (payload) => {
      const { uuid, cb } = payload
      const { data } = await api(dispatch, false).get(
        `${API_VERSION}/products/${uuid}/wishlists/add`
      )
      if (cb) cb()
      dispatch({ type: 'wishListCount', payload: data.wishlisted })
    }),
    []
  )

  const removeFromWishList = useCallback(
    withTryCatch(async (payload) => {
      const { uuid, cb } = payload
      const { data } = await api(dispatch, false).get(
        `${API_VERSION}/products/${uuid}/wishlists/remove`
      )
      if (cb) cb()
      dispatch({ type: 'wishListCount', payload: data.wishlisted })
    }),
    []
  )

  const loadMyAdverts = useCallback(
    withTryCatch(async () => {
      const { data } = await api(dispatch).get(`${API_VERSION}/products/my`)
      dispatch({ type: 'adverts', payload: data })
    }),
    []
  )

  const createProduct = useCallback(
    withTryCatch(async (body, cb) => {
      await api(dispatch).post(`${API_VERSION}/products`, body)
      // dispatch({ type: 'adverts', payload: data })
      if (cb) cb()
    }),
    []
  )

  const getProduct = useCallback(
    withTryCatch(async (uuid) => {
      const { data } = await api(dispatch).get(`${API_VERSION}/products/${uuid}`)
      dispatch({ type: 'currentProduct', payload: data })
    }),
    []
  )

  const getPendingProducts = useCallback(
    withTryCatch(async (uuid) => {
      const { data } = await api(dispatch).get(`${API_VERSION}/products/pending`)
      dispatch({ type: 'pendingProducts', payload: data })
    }),
    []
  )

  const loadSimilarProductsById = useCallback(
    withTryCatch(async (uuid) => {
      const { data } = await api(dispatch).get(`${API_VERSION}/products/${uuid}/similar_products`)
      dispatch({ type: 'similarProducts', payload: data })
    }),
    []
  )

  const updateProduct = useCallback(
    withTryCatch(async (uuid, body, cb) => {
      await api(dispatch).put(`${API_VERSION}/products/${uuid}`, body)
      if (cb) cb()
    }),
    []
  )

  const deleteProduct = useCallback(
    withTryCatch(async (uuid) => {
      await api(dispatch).delete(`${API_VERSION}/products/${uuid}`)
    }),
    []
  )

  const deleteConversation = useCallback(
    withTryCatch(async (uuid, tab) => {
      await api(dispatch).delete(`${API_VERSION}/conversations/${uuid}`)
      if (tab === BUY) dispatch({ type: 'deleteBuyConversation', payload: uuid })
      if (tab === SELL) dispatch({ type: 'deleteSELLConversation', payload: uuid })
    }),
    []
  )

  const findProductByGTIN = useCallback(
    withTryCatch(async (gtin) => {
      const { data } = await api(dispatch).get(`${API_VERSION}/products/locate?gtin=${gtin}`) //"978-5-907143-26-5"
      const manufacturer = [data.manufacturer]
      const response = {
        ...data,
        manufacturer,
      }
      dispatch({ type: 'manufacturers', payload: manufacturer })
      return response
    }),
    []
  )

  const loadOrders = useCallback(
    withTryCatch(async (payload) => {
      const { data } = await api(dispatch).get(
        `${API_VERSION}/orders${payload ? `?filter=${payload}` : ''}`
      )
      dispatch({ type: 'orders', payload: data })
    }),
    []
  )

  const postOrder = useCallback(
    withTryCatch(async (payload) => {
      return await api(dispatch).post(`${API_VERSION}/orders`, payload)
    }),
    []
  )

  const updateOrders = useCallback(
    withTryCatch(async (uuid, status, cb) => {
      await api(dispatch).put(`${API_VERSION}/orders/${uuid}?act=${status}`)
      if (cb) cb()
    }),
    []
  )

  const updateUser = useCallback(
    withTryCatch(async (uuid, body, cb) => {
      const { data } = await api(dispatch).put(`${API_VERSION}/users/${uuid}`, body)
      dispatch({ type: 'user', payload: data })
      setUser(data)
      if (cb) cb()
    }),
    []
  )

  // Not used anymore
  const loadBuyingMessages = useCallback(
    withTryCatch(async () => {
      const { data } = await api(dispatch).get(`${API_VERSION}/conversations/buying`)
      dispatch({ type: 'buying', payload: data })
    }),
    []
  )

  const loadMessagesByProductIdAndBuyerId = useCallback(
    withTryCatch(async ({ type, ...payload }) => {
      const { data } = await api(dispatch).post(`${API_VERSION}/conversations`, payload)
      dispatch({ type, payload: data })
    }),
    []
  )

  // Not used anymore
  const loadSellingMessages = useCallback(
    withTryCatch(async () => {
      const { data } = await api(dispatch).get(`${API_VERSION}/conversations/selling`)
      dispatch({ type: 'selling', payload: data })
    }),
    []
  )

  const loadMessages = useCallback(
    withTryCatch(async (uuid) => {
      const { data } = await api(dispatch).get(`${API_VERSION}/conversations/${uuid}/messages`)
      dispatch({ type: 'messages', payload: { uuid, data } })
    }),
    []
  )

  const sendMessages = useCallback(
    withTryCatch(async (uuid, body) => {
      const { data } = await api(dispatch).post(
        `${API_VERSION}/conversations/${uuid}/messages`,
        body
      )
      dispatch({ type: 'addMessages', payload: { uuid, message: data } })
    }),
    []
  )

  const readMessage = useCallback(
    withTryCatch(async (uuid, activeTab, activeConversationUuid) => {
      await api(dispatch, false).get(`${API_VERSION}/messages/${uuid}/read`)
      dispatch({ type: 'markMsgRead', payload: { uuid, activeTab, activeConversationUuid } })
    }),
    []
  )

  const loadCategories = useCallback(
    withTryCatch(async (term) => {
      const { data } = await api(dispatch).get(
        `${API_VERSION}/categories${term ? `?term=${term}` : ''}`
      )
      dispatch({ type: 'categories', payload: data })
    }),
    []
  )

  const loadManufacturers = useCallback(
    withTryCatch(async (term) => {
      const { data } = await api(dispatch, false).get(`${API_VERSION}/manufacturers?term=${term}`)
      dispatch({ type: 'manufacturers', payload: data.manufacturers })
    }),
    []
  )

  const loadCountries = useCallback(
    withTryCatch(async () => {
      const { data } = await api(dispatch, false).get(`${API_VERSION}/countries`)
      dispatch({ type: 'countries', payload: data.countries })
    }),
    []
  )

  const loadZonesByCountryId = useCallback(
    withTryCatch(async (countryId) => {
      const { data } = await api(dispatch, false).get(`${API_VERSION}/countries/${countryId}/zones`)
      dispatch({ type: 'zones', payload: data.zones })
    }),
    []
  )

  const loadDeliveries = useCallback(
    withTryCatch(async () => {
      const { data } = await api(dispatch).get(`${API_VERSION}/deliveries`)
      dispatch({ type: 'deliveries', payload: data.deliveries })
    }),
    []
  )

  const loadConditions = useCallback(
    withTryCatch(async () => {
      const { data } = await api(dispatch).get(`${API_VERSION}/conditions`)
      dispatch({ type: 'conditions', payload: data.conditions })
    }),
    []
  )

  const deleteImageById = useCallback(
    withTryCatch(async (id) => {
      await api(dispatch).delete(`${API_VERSION}/images/${id}`)
    }),
    []
  )

  const uploadImage = useCallback(
    withTryCatch(async (file) => {
      const { rest } = await api(dispatch).put(`${API_VERSION}/images/upload`, file, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      return rest
    }),
    []
  )

  const loadProductsBuying = useCallback(
    withTryCatch(async (isCombine) => {
      const { data } = await api(dispatch).get(`${API_VERSION}/products/buying`)
      console.log('Buying data> ', data)
      dispatch({ type: 'buyingProducts', payload: data.products, isCombine })
    }),
    []
  )

  const loadProductsSelling = useCallback(
    withTryCatch(async (isCombine) => {
      const { data } = await api(dispatch).get(`${API_VERSION}/products/selling`)
      console.log('Selling data> ', data)
      dispatch({ type: 'sellingProducts', payload: data.products, isCombine })
    }),
    []
  )

  const setActiveConversation = useCallback((payload) => {
    dispatch({ type: 'activeConversation', payload })
  }, [])

  const setActiveConversationUuid = useCallback((uuid) => {
    dispatch({ type: 'activeConversationUuid', payload: uuid })
  }, [])

  const setActiveProduct = useCallback((product) => {
    dispatch({ type: 'activeProduct', payload: product })
  }, [])

  const handleSearchConversation = useCallback((type, text) => {
    if (type === SELL) {
      dispatch({ type: 'searchSellConversation', payload: text })
    } else {
      dispatch({ type: 'searchBuyConversation', payload: text })
    }
  }, [])

  return (
    <ApiContext.Provider
      value={{
        ...state,
        setActiveConversationUuid,
        setActiveConversation,
        setActiveProduct,
        handleSearchConversation,
        loadProductsBuying,
        loadProductsSelling,
        createProduct,
        deleteImageById,
        deleteProduct,
        findProductByGTIN,
        getProduct,
        getPendingProducts,
        loadAdvById,
        loadBuyingMessages,
        loadCategories,
        loadConditions,
        loadDeliveries,
        loadHomeProductsList,
        loadManufacturers,
        loadCountries,
        loadZonesByCountryId,
        loadMessages,
        loadMyAdverts,
        loadOrders,
        loadSellingMessages,
        loadSimilarProductsById,
        loadMessagesByProductIdAndBuyerId,
        loadWishList,
        deleteConversation,
        sendMessages,
        readMessage,
        addToWishList,
        removeFromWishList,
        getUserStats,
        loginUser,
        logOut,
        postOrder,
        postUser,
        updateOrders,
        updateProduct,
        updateUser,
        uploadImage,
        passwordResetRequest,
        passwordResetValidate,
        passwordReset,
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}

import React from 'react'
import avatarImg from '../../images/avatar/unknown.png'
import { getFormattedDate } from '../../utils/dateFotmater'
import Counter from '../../components/Counter'
// import { ApiContext } from '../../containers/ApiContainer'
import TextButton from '../../components/TextButton'

import styles from './MessengerPage.module.scss'

const Conversation = ({
  uuid,
  buyer,
  isActive,
  onClick,
  order,
  lastUpdated,
  product = { seller: {} },
  unreadMessagesCount = 0,
}) => {
  const username = `${buyer.firstname} ${buyer.lastName || ''}`
  return (
    <div
      onClick={onClick}
      id={uuid}
      className={`${styles.conversationInProduct} ${isActive ? styles.selected : ''}`}
    >
      <div className={styles.col2}>
        <img className={styles.avatar} src={avatarImg} alt={''} />
        <div className={styles.userInfoBlock}>
          <div className={styles.grayColor}>
            {username}
          </div>
          <div className={styles.lightGrayColor}>
            {product.seller.address?.city ? `(${product.seller.address?.city})` : ''}
          </div>
        </div>
      </div>
      <div className={styles.col3}>
        <div className={styles.lightGrayColor}>{getFormattedDate(lastUpdated)}</div>
        <div className={styles.rightBlock}>
          {order?.status?.id === 700 && (
            <TextButton className={styles.sold} color="Red">
              Продано
            </TextButton>
          )}
          <Counter className={styles.unreadCount} count={unreadMessagesCount} />
        </div>
      </div>
    </div>
  )
}

export default Conversation

import React, { useCallback, useContext } from 'react'
import { Link } from 'react-router-dom'
import styles from './AuthForm.module.scss'
import { getFormattedDate } from '../../utils/dateFotmater'
import { PROFILE_PAGE, PWD_RESET_PAGE, HOME_PAGE } from '../../constants'
import { ApiContext } from '../../containers/ApiContainer'

const LoggedInForm = ({ user, onFinish }) => {
  const { logOut } = useContext(ApiContext)

  const onLogOut = useCallback(() => {
    logOut()
    onFinish()
    setTimeout(() => window.location.reload(), 1000);
  }, [logOut, onFinish])

  return (
    <>
      <div className={styles.header}>
        <div className={styles.h1}>
          {user ? `${user?.firstname} ${user?.lastname}` : 'Вход в сервис'}
        </div>
        <div className={styles.h2}>
          С нами с {getFormattedDate(user?.dateAdded)}
        </div>
      </div>
      <div  className={styles.link}>
        <Link to={PROFILE_PAGE} onClick={onFinish}>Профиль</Link>
      </div>
      <div  className={styles.link}>
        <Link to={PWD_RESET_PAGE} onClick={onFinish}>Сменить пароль</Link>
      </div>
      <div  className={styles.link}>
        <Link to={HOME_PAGE} onClick={onLogOut}>Выйти</Link>
      </div>
    </>
  )
}

export default React.memo(LoggedInForm)

import React from 'react'

import styles from './MessengerPage.module.scss'

const SwitchToConversation = ({ onClick }) => (
  <div className={styles.conversationSwitcher} onClick={onClick}>
    Перейти к списку диалогов
  </div>
)

export default SwitchToConversation

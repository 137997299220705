import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import GridContainerBlock from '../../components/GridContainerBlock'
import ContentBlock from '../../components/ContentBlock'
import PageBackTitle from '../../components/PageBackTitle'
import Title from '../../components/Title'
import styles from './PwdResetPage.module.scss'
import FormItem from '../../components/FormItem'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Form from '../../components/Form'
import { useForm } from '../../hooks/useForm'
import { useQueryParams } from '../../hooks/useQueryParams'
import { formSchema } from './formSchema'
import { ApiContext } from '../../containers/ApiContainer'
import { NotificationManager } from 'react-notifications'
import { HOME_PAGE } from '../../constants'

const PwdResetPage = ({ error }) => {
  const { user, passwordReset, passwordResetValidate } = useContext(ApiContext)

  const form = useForm()
  const history = useHistory()
  const { code } = useQueryParams()

  const [pwdChanged, setPwdChanged] = useState(() => false)

  useEffect(() => {
    if (!user) {
      if (code) {
        const body = { user: { code: code } }
        passwordResetValidate({ body })
          .then(() => {
            form.setState({ code })
            NotificationManager.success('Код востановления корректный.')
          })
          .catch((e) => {
            console.log('code error')
            NotificationManager.error(e?.data?.error)
            return history.push(HOME_PAGE)
          })
      } else {
        NotificationManager.error('Нет Авторизации')
        return history.push(HOME_PAGE)
      }
    }

    // wee expect execute only one time when user open the page
    // eslint-disable-next-line
  }, [])

  const prepareRequestBody = useCallback(() => {
    const { value, error } = formSchema.validate(form.state, {
      stripUnknown: true /*abortEarly: false*/,
    })
    if (error) {
      console.error('ValidationError:', error)
      alert(error)
      return
    }

    return value
  }, [form.state])

  const handleSubmit = useCallback(() => {
    const preparedBody = prepareRequestBody()
    if (preparedBody) {
      const body = {
        user: preparedBody,
      }
      passwordReset({
        body,
        cb() {
          setPwdChanged(true)
          NotificationManager.success('Пароль успешно обновлен.')
        },
      })
    }
  }, [prepareRequestBody, passwordReset, setPwdChanged])

  return (
    <GridContainerBlock>
      <ContentBlock noMargin>
        <PageBackTitle title="Смена Пароля" />
        <Title text="Смена Пароля" />
        {pwdChanged ? (
          <p className={styles.textGreen}>
            Пароль успешно обновлен! Вернуться на
            <span className={styles.link}>
              <Link to={HOME_PAGE}> Домашнюю </Link>
            </span>
            страницу.
          </p>
        ) : (
          <div>
            <p className={styles.text}>Введите новый пароль и нажмите “Изменить пароль”</p>
            <div className={styles.formSection}>
              <div>
                <Form form={form}>
                  <Input type="hidden" name="code" inputRowCN={styles.formInputHidden} />
                  <FormItem label="Пароль" name="password">
                    <Input iconType="eye" type="password" />
                  </FormItem>
                  <FormItem label="Подтвердите Пароль" name="passwordConfirmation">
                    <Input iconType="eye" type="password" />
                  </FormItem>
                  <div className={styles.submit}>
                    <Button onClick={handleSubmit}>Изменить пароль</Button>
                    {error && <div className={styles.error}>{error}</div>}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
      </ContentBlock>
    </GridContainerBlock>
  )
}

export default PwdResetPage

import React, { useContext, useEffect, useMemo } from 'react'
import styles from './MessengerPage.module.scss'
import Counter from '../../components/Counter'
import { ApiContext } from '../../containers/ApiContainer'
import { useEventHandlerById, useSetActiveConversation } from './hooks'
import avatarImg from '../../images/avatar/unknown.png'
import { getFormattedDate } from '../../utils/dateFotmater'
import { useQueryParams } from '../../hooks/useQueryParams'
import SearchBlock from './SearchBlock'
import { BUY } from './MessengerPage'

const ConversationsList = ({ onSelect }) => {
  const {
    user,
    messages,
    setActiveProduct,
    setActiveConversationUuid,
    loadMessagesByProductIdAndBuyerId,
  } = useContext(ApiContext)
  const { buying, activeProduct } = messages
  const { productUuid, tab } = useQueryParams()
  const [activeConversationUuid, handleChangeActiveConversation, setActive] = useEventHandlerById()
  const activeConversation = useMemo(
    () =>
      buying.find(({ conversation }) => conversation?.uuid === activeConversationUuid)
        ?.conversation,
    [activeConversationUuid, buying]
  )
  useSetActiveConversation(activeConversationUuid, activeConversation)

  // Active first message
  useEffect(() => {
    const uuid = buying[0]?.conversation?.uuid
    uuid && setActive(uuid)
  }, [buying, setActive])

  useEffect(() => {
    if (productUuid && tab === BUY) {
      // load only for first time when page has been opened
      loadMessagesByProductIdAndBuyerId({
        aProductUuid: productUuid,
        buyerUuid: user.uuid,
        type: 'buying2',
      })
    }
    return () => {
      setActiveProduct(undefined)
      setActiveConversationUuid(undefined)
    }
  }, [productUuid]) // eslint-disable-line

  useEffect(() => {
    if (activeConversationUuid) {
      // set on each time when user click on new product in product list
      const product = buying.find(
        ({ conversation }) => conversation.uuid === activeConversationUuid
      )
      setActiveProduct(product)
    }
  }, [setActiveProduct, activeConversationUuid, buying])

  return (
    <div className={styles.left}>
      <SearchBlock tab={BUY} />
      <div className={styles.conversationsList}>
        {buying.map(({ uuid, mainImage = {}, name, conversation, seller = {} }) => (
          <div
            id={conversation?.uuid}
            key={uuid}
            onClick={(e) => {
              onSelect()
              handleChangeActiveConversation(e)
            }}
            className={`${styles.conversation} ${
              uuid === activeProduct?.uuid ? styles.selected : ''
            }`}
          >
            <div>
              <img className={styles.advImage} src={mainImage?.url} alt={name} title={name} />
            </div>
            <div>
              <div className={styles.productName}>{name}</div>
              <div className={styles.col2}>
                <img className={styles.avatar} src={avatarImg} alt={''} />
                <div className={styles.userInfoBlock}>
                  <div className={styles.grayColor}>{`${seller?.firstname || ''} ${
                    seller?.lastname || ''
                  }`}</div>
                  <div className={styles.lightGrayColor}>
                    {seller.address?.city ? `(${seller.address?.city})` : ''}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.col3}>
              <div className={styles.lightGrayColor}>
                {getFormattedDate(conversation?.lastUpdated)}
              </div>
              <div className={styles.maT20}>
                <Counter className={styles.unreadCount} count={conversation?.unreadMessagesCount} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ConversationsList

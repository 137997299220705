import { useCallback } from 'react'

let isSearchOpened = false

export const useSearchBar = () => {
  const toggleSearch = useCallback(() => {
    isSearchOpened = !isSearchOpened
  }, [])

  return { isSearchOpened, toggleSearch }
}

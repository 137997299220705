import React, { useCallback, useContext, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import styles from './Adv.module.scss'
import { useHistory } from 'react-router-dom'
import { ADV_CARD_PAGE } from '../../constants'
import Title from '../Title'
import Distance from '../Distance'
import { ApiContext } from '../../containers/ApiContainer'
import { showPleaseLogInError } from '../../utils/showPleaseLogInError'
import { useGoToMessengerPage } from '../../hooks/useGoToMessengerPage'

const Adv = ({
  uuid,
  image,
  mainImage,
  price,
  name,
  productUrl,
  seller,
  wishlist,
  actionButtons = false,
}) => {
  const history = useHistory()
  const { openBuyTab } = useGoToMessengerPage()
  const { user, addToWishList, removeFromWishList } = useContext(ApiContext)

  const distance = seller?.address?.distance

  const openAdvPage = useCallback(() => {
    if (!productUrl) return history.push(ADV_CARD_PAGE.replace(':uuid', uuid))
    const a = document.createElement('a')
    a.href = productUrl
    a.target = '_blank'
    a.click()
    a.remove()
  }, [productUrl, history, uuid])

  const [isActive, setActive] = useState(() => wishlist)

  const handleShowNotification = useCallback((msg, title) => {
    NotificationManager.success(msg, title)
  }, [])

  const onFavoriteClick = useCallback(
    (e) => {
      e.stopPropagation()
      if (!user) return showPleaseLogInError()

      const apiFunc = isActive ? removeFromWishList : addToWishList
      const msg = isActive ? 'удален из избранных' : 'добавлен в избранные'
      apiFunc({
        uuid,
        cb() {
          handleShowNotification(`Товар успешно ${msg}.`)
          setActive((isActive) => !isActive)
        },
      })
    },
    [isActive, user, handleShowNotification, addToWishList, removeFromWishList, uuid]
  )

  const handleNewOrder = useCallback(
    (e) => {
      e.stopPropagation()
      if (!user) return showPleaseLogInError()
      openBuyTab(uuid)
    },
    [openBuyTab, user, uuid]
  )

  const truncate = (str) => {
    return str.length > 35 ? str.substring(0, 32) + '...' : str
  }

  return (
    <article id={uuid} className={styles.adv} onClick={openAdvPage}>
      <div className={styles.imageWrapper}>
        <span className={styles.advPrice}>
          {price}
          <Distance distance={distance} />
        </span>
        {actionButtons && (!user || (user && seller.uuid !== user.uuid)) && (
          <span
            onClick={onFavoriteClick}
            className={isActive ? styles.favoriteOn : styles.favoriteOff}
          />
        )}
        <img
          src={mainImage?.previewUrl || mainImage?.url || image}
          className={styles.advImage}
          title={name}
          alt={name}
        />
      </div>
      {actionButtons && (
        <div className={styles.advBottomWrapper}>
          <Title className={styles.advTitle} text={truncate(name)} />
          {(!user || (user && seller.uuid !== user.uuid)) && (
            <span onClick={handleNewOrder} className={styles.order} />
          )}
        </div>
      )}
    </article>
  )
}

export default Adv

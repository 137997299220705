import React from 'react'
import ContentBlock from '../ContentBlock'
import GridContainerBlock from '../GridContainerBlock'

const SpinnerBlock = () => {
  return (
    <GridContainerBlock>
      <ContentBlock>Идет загрузка...</ContentBlock>
    </GridContainerBlock>
  )
}

export default SpinnerBlock

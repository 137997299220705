import React, { useState, useCallback, useContext, useEffect } from 'react'
import GridContainerBlock from '../../components/GridContainerBlock'
import ContentBlock from '../../components/ContentBlock'
import SpinnerBlock from '../../components/SpinnerBlock'
import { ApiContext } from '../../containers/ApiContainer'
import RowForAdv from '../../components/RowForAdv'
import AdvRowBlock from '../../components/AdvRowBlock'
import AdvImage from '../../components/AdvImage'
import Adv from '../../components/Adv'
import AdvInfoBlock from '../../components/AdvInfoBlock'
import Title from '../../components/Title'
import { getFormattedDate } from '../../utils/dateFotmater'
import DeleteActionBlock from '../../components/DeleteActionBlock'
import TextButton from '../../components/TextButton'
import Pagination from '../../components/Pagination'

const ReasonPrompt = ({
  uuid,
  reasonText,
  handleChangeReason,
  handleCloseReason,
  handleUpdateAdvert,
}) => (
  <>
    <textarea
      placeholder="Причина отклонения"
      onChange={({ target }) => handleChangeReason(target.value)}
      value={reasonText}
    />
    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
      <TextButton
        data-status-id="400"
        data-uuid={uuid}
        data-reason={reasonText}
        onClick={handleUpdateAdvert}
        color="Green"
      >
        Ок
      </TextButton>
      <TextButton onClick={handleCloseReason} color="Red">
        Отмена
      </TextButton>
    </div>
  </>
)

const NewAdvertModerationPage = () => {
  const { updateProduct, isLoading, getPendingProducts, pendingProducts } = useContext(ApiContext)
  const { products = [], summary } = pendingProducts || {}
  const [reasons, setReasons] = useState({})

  useEffect(() => {
    getPendingProducts()
  }, []) // eslint-disable-line

  const handleUpdateAdvert = useCallback(
    (e) => {
      const { uuid, statusId } = e.target.dataset
      const body = { product: { aProductStatusId: statusId } }
      if (statusId === '400') body.product.rejection_reason = reasons[uuid].text
      updateProduct(uuid, body, getPendingProducts)
    },
    [getPendingProducts, updateProduct, reasons]
  )

  const handleOpenReason = (uuid) => {
    setReasons((reasons) => ({ ...reasons, [uuid]: { opened: true } }))
  }

  const handleCloseReason = (uuid) => {
    setReasons((reasons) => ({ ...reasons, [uuid]: { opened: false } }))
  }

  const handleChangeReason = (uuid, text) => {
    setReasons((reasons) => ({ ...reasons, [uuid]: { opened: true, text } }))
  }

  if (isLoading) return <SpinnerBlock />

  return (
    <GridContainerBlock>
      <ContentBlock>
        {products.map(({ status, ...adv }) => (
          <RowForAdv key={adv.uuid}>
            <ContentBlock noMargin>
              <AdvRowBlock>
                <AdvImage>
                  <Adv {...adv} />
                </AdvImage>
                <AdvInfoBlock>
                  <Title text={adv.name} />
                  <div>
                    <div>Создано:&nbsp;{getFormattedDate(adv?.createdAt)}</div>
                    {adv?.publishedAt && (
                      <div>Опубликовано:&nbsp;{getFormattedDate(adv?.publishedAt)}</div>
                    )}
                    {adv?.author && <div>Автор: {adv.author}</div>}
                  </div>
                  <div>
                    Статус: <strong>{status?.name}</strong>
                  </div>
                  {reasons[adv.uuid]?.opened && (
                    <ReasonPrompt
                      uuid={adv.uuid}
                      reasonText={reasons[adv.uuid].text}
                      handleChangeReason={(text) => handleChangeReason(adv.uuid, text)}
                      handleCloseReason={() => handleCloseReason(adv.uuid)}
                      handleUpdateAdvert={handleUpdateAdvert}
                    />
                  )}
                </AdvInfoBlock>
                <DeleteActionBlock>
                  <TextButton
                    data-status-id="500"
                    data-uuid={adv.uuid}
                    onClick={handleUpdateAdvert}
                    color="Green"
                  >
                    Опубликовать
                  </TextButton>
                  <TextButton
                    data-status-id="400"
                    data-uuid={adv.uuid}
                    onClick={() => handleOpenReason(adv.uuid)}
                    color="Red"
                  >
                    Отклонить
                  </TextButton>
                </DeleteActionBlock>
              </AdvRowBlock>
            </ContentBlock>
          </RowForAdv>
        ))}
        {summary?.pages > 1 && (
          <RowForAdv>
            <ContentBlock noMargin>
              <Pagination totalPages={summary.pages} currentPage={summary.page} />
            </ContentBlock>
          </RowForAdv>
        )}
      </ContentBlock>
    </GridContainerBlock>
  )
}

export default NewAdvertModerationPage

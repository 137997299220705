import React from 'react'
import styles from './SocialBlock.module.scss'

export const SocialBlock = () => (
  <div className={styles.social}>
    <a className={styles.mailUs} href="mailto:adv@bookashka.co.uk">
      adv@bookashka.co.uk
    </a>
    <div className={styles.inSocial}>Мы в социальных сетях</div>
    <a href="https://www.facebook.com/Bookashkashop" target="_blank" rel="noopener noreferrer">
      <span className={styles.facebook} />
    </a>
    <a href="https://www.instagram.com/Bookashkashop" target="_blank" rel="noopener noreferrer">
      <span className={styles.instagram} />
    </a>
  </div>
)

export const SocialLinks = ({ className = '' }) => (
  <div className={className}>
    <div className={styles.inSocial}>Мы в социальных сетях</div>
    <a href="https://www.facebook.com/Bookashkashop" target="_blank" rel="noopener noreferrer">
      <span className={styles.facebook} />
    </a>
    <a href="https://www.instagram.com/Bookashkashop" target="_blank" rel="noopener noreferrer">
      <span className={styles.instagram} />
    </a>
  </div>
)

export const MobileSocialBlock = () => (
  <div className={styles.social}>
    <div className={styles.leftSocial}>
      <div>Пишите нам на почту</div>
      <a className={styles.mailUs} href="mailto:adv@bookashka.co.uk">
        adv@bookashka.co.uk
      </a>
    </div>
    <SocialLinks />
  </div>
)

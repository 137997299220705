import React from 'react'
import styles from './Menu.module.scss'
import { NavLink } from 'react-router-dom'
import {
  MY_ADS_PAGE,
  MY_ADS_PAGE_TITLE,
  MY_ORDERS_PAGE,
  MY_ORDERS_PAGE_TITLE,
  NEW_ADVERT_PAGE,
  NEW_ADVERT_PAGE_TITLE,
  NEW_ADVERTS_MODERATION_PAGE,
  NEW_ADVERTS_MODERATION_PAGE_TITLE,
  RULES_PAGE,
  RULES_PAGE_TITLE,
} from '../../constants'
import CartIcon from '../../images/icons/cartSvgIcon.svg'
import AdsIcon from '../../images/icons/adsSvgIcon.svg'
import { ReactComponent as ModerationIcon } from '../../images/icons/moderation.svg'
import NewAdsIcon from '../../images/icons/newAdsSvgIcon.svg'

const menuList = [
  { id: 1, link: RULES_PAGE, title: RULES_PAGE_TITLE },
  { id: 2, link: MY_ADS_PAGE, title: MY_ADS_PAGE_TITLE },
  { id: 3, link: MY_ORDERS_PAGE, title: MY_ORDERS_PAGE_TITLE },
]

export const MenuItem = ({ children, ...props }) => {
  return (
    <div className={styles.menuLink} {...props}>
      {children}
    </div>
  )
}

export const RulePageNavLink = ({ onClick, className = '' }) => {
  return (
    <NavLink
      to={RULES_PAGE}
      alt={RULES_PAGE_TITLE}
      title={RULES_PAGE_TITLE}
      onClick={onClick}
      className={`${styles.menuLink} ${className}`}
      activeClassName={styles.selectedMenu}
    >
      Правила
    </NavLink>
  )
}

export const MyAdsPageNavLink = ({ onClick, withIcon, hideText = false }) => {
  return (
    <NavLink
      to={MY_ADS_PAGE}
      alt={MY_ADS_PAGE_TITLE}
      title={MY_ADS_PAGE_TITLE}
      onClick={onClick}
      className={styles.menuLink}
      activeClassName={styles.selectedMenu}
    >
      {withIcon && <AdsIcon className={styles.icon} />}
      {!hideText && `${MY_ADS_PAGE_TITLE}`}
    </NavLink>
  )
}

export const MyOrdersPageNavLink = ({ onClick, withIcon, hideText = false }) => {
  return (
    <NavLink
      to={MY_ORDERS_PAGE}
      alt={MY_ORDERS_PAGE_TITLE}
      title={MY_ORDERS_PAGE_TITLE}
      onClick={onClick}
      className={styles.menuLink}
      activeClassName={styles.selectedMenu}
    >
      {withIcon && <CartIcon className={styles.icon} />}
      {!hideText && `${MY_ORDERS_PAGE_TITLE}`}
    </NavLink>
  )
}

export const NewAdvertPageNavLink = ({ withIcon, hideText = false }) => {
  return (
    <NavLink to={NEW_ADVERT_PAGE} className={styles.menuLink} activeClassName={styles.selectedMenu}>
      {withIcon && <NewAdsIcon className={styles.icon} />}
      {!hideText && `${NEW_ADVERT_PAGE_TITLE}`}
    </NavLink>
  )
}

export const NewAdvertsModerationPageNavLink = ({ withIcon, hideText = false }) => {
  return (
    <NavLink
      to={NEW_ADVERTS_MODERATION_PAGE}
      className={styles.menuLink}
      activeClassName={styles.selectedMenu}
    >
      {withIcon && <ModerationIcon className={styles.icon} />}
      {!hideText && `${NEW_ADVERTS_MODERATION_PAGE_TITLE}`}
    </NavLink>
  )
}

const Menu = ({ className, linkClassName = '', selectedMenuClassName = '' }) => {
  return (
    <ul className={`${className} ${styles.hzList}`}>
      {menuList.map(({ id, link, title }) => (
        <li key={id}>
          <NavLink to={link} className={linkClassName} activeClassName={selectedMenuClassName}>
            {title}
          </NavLink>
        </li>
      ))}
    </ul>
  )
}

export default Menu

import React, { useCallback, useEffect } from "react";
import styles from './Modal.module.scss'

const Modal = ({ onClose, children }) => {
  const onESC = useCallback((e) => {
    if (e.keyCode === 27) {
      onClose()
    }
  }, [onClose])

  useEffect(() => {
    document.addEventListener("keydown", onESC)
    return () => document.removeEventListener("keydown", onESC)
  }, [onESC])

  return (
    <div className={styles.container} >
      <div className={styles.shadow} onClick={onClose} />
      <div className={styles.block}>
        <div className={styles.close}>
          <span className={styles.closeButton} onClick={onClose}/>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal

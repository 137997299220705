export const ADV_CARD_PAGE = '/adv/:uuid'
export const NEW_ADVERT_PAGE = '/advert/new'
export const NEW_ADVERT_PAGE_TITLE = 'Новое'
export const NEW_ADVERTS_MODERATION_PAGE = '/new-adverts-moderation'
export const NEW_ADVERTS_MODERATION_PAGE_TITLE = 'Модерирование'
export const EDIT_ADVERT_PAGE = '/advert/:uuid'
export const MY_ADS_PAGE = '/my-ads'
export const MY_ADS_PAGE_TITLE = 'Мои объявления'
export const MY_ORDERS_PAGE = '/my-orders'
export const MY_ORDERS_PAGE_TITLE = 'Мои заказы'
export const RULES_PAGE = '/rules'
export const RULES_PAGE_TITLE = 'Правила'
export const FAVORITES_PAGE = '/favorites'
export const FAVORITES_PAGE_TITLE = 'Избранное'
export const MESSENGER_PAGE = '/messenger'
export const MESSENGER_PAGE_TITLE = 'Сообщения'
export const LOADING_MESSAGE = 'Проверяем...'
export const PROFILE_PAGE = '/profile'
export const PWD_RESET_REQUEST_PAGE = '/pwd-reset-request'
export const PWD_RESET_PAGE = '/pwd-reset'
export const HOME_PAGE = '/'
export const BOOKASHKA_HOME_PAGE = 'https://bookashka.co.uk'

export const BUTTON_SAVE = 'Сохранить'
export const BUTTON_PUBLISH = 'Опубликовать'
export const BUTTON_CANCEL = 'Отменить'

export const EXTRA_LARGE_SCREEN_SIZE = '(min-width: 1601px)'
export const LARGE__ONLY_SCREEN_SIZE = '(max-width: 1600px) and (min-width: 1025px)'
export const LARGE_SCREEN_SIZE = '(max-width: 1600px)'
export const MEDIUM_ONLY_SCREEN_SIZE = '(max-width: 1024px) and (min-width: 513px)'
export const MEDIUM_SCREEN_SIZE = '(max-width: 1024px)'
export const MOBILE_SCREEN_SIZE = '(max-width: 512px)'
export const TABLET_SCREEN_SIZE = '(max-width: 900px)'

export const SORT_BY = [
  { id: 'newest', name: 'Недавно опубликованный' },
  { id: 'closest', name: 'Ближайший' },
  { id: 'cheapest', name: 'Дешевый в начале' },
  { id: 'priciest', name: 'Дорогой в начале' },
  { id: 'oldest', name: 'Давно опубликованный' },
]

export const DESTINATIONS_LIST = [
  { id: 0, name: 'Любое' },
  { id: 5, name: '5 км' },
  { id: 10, name: '10 км' },
  { id: 50, name: '50 км' },
  { id: 100, name: '100 км' },
]

export const ORDER_STATUSES = [
  { id: 0, filterValue: '', name: 'Все заказы' },
  // { id: 600, filterValue: 'reserved', name: 'Зарезервирован' },
  { id: 700, filterValue: 'completed', name: 'Выполненные' },
  // { id: 850, filterValue: 'cancelled', name: 'Отменен' },
  // { id: 550, filterValue: 'inactive', name: 'Не Активен' },
  { id: 900, filterValue: 'archived', name: 'Архивные' },
]

import React, { useState } from 'react'
import MessageContainer from './MessageContainer'
import ConversationsList from './ConversationsList'
import { BUY } from './MessengerPage'
import SwitchToDialogs from './SwitchToConversation'

import styles from './MessengerPage.module.scss'

const Buy = () => {
  const [showDialogs, setShowDialogs] = useState(true)

  return (
    <div
      className={`${styles.container} ${showDialogs ? styles.showDialogs : styles.showMessages}`}
    >
      {!showDialogs && <SwitchToDialogs onClick={() => setShowDialogs(true)} />}
      <ConversationsList onSelect={() => setShowDialogs(false)} />
      <MessageContainer tab={BUY} />
    </div>
  )
}

export default Buy

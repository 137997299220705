import React, { useState } from 'react'
import MessageContainer from './MessageContainer'
import ProductListWithConversations from './ProductListWithConversations'
import { SELL } from './MessengerPage'
import SwitchToDialogs from './SwitchToConversation'

import styles from './MessengerPage.module.scss'

const Sell = () => {
  const [showDialogs, setShowDialogs] = useState(true)

  return (
    <div
      className={`${styles.container} ${showDialogs ? styles.showDialogs : styles.showMessages}`}
    >
      {!showDialogs && <SwitchToDialogs onClick={() => setShowDialogs(true)} />}
      <ProductListWithConversations onSelect={() => setShowDialogs(false)} />
      <MessageContainer tab={SELL} />
    </div>
  )
}

export default Sell

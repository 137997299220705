import { isObject } from './isObject'
import { makeClearObject } from './makeClearObject'
import { keysToSnakeCase } from './keysToSpecificCase'

export const createQueryStringFromObject = (parameters, isSnakeCase = true) => {
  if (!isObject(parameters)) {
    return ''
  }

  const parametersWithBEKeys = isSnakeCase ? keysToSnakeCase(parameters) : parameters

  return Object.entries(makeClearObject(parametersWithBEKeys)).reduce(
    (acc, [key, value], index, arr) => {
      acc += `${key}=${encodeURIComponent(value)}${index < arr.length - 1 ? '&' : ''}`

      return acc
    },
    '?'
  )
}

import React, { useMemo, useCallback } from 'react'
import styles from './Pagination.module.scss'
import { generatePageRange } from '../../utils/generatePageRange'

export const PageButton = ({ onClick, label, className = '' }) => (
  <div
    onClick={onClick}
    className={`${styles.pageButton} ${className}`}
    dangerouslySetInnerHTML={{ __html: label }}
  />
)

const Pagination = ({
  onClick,
  currentPage = 1,
  totalPages = 1,
  withShowMore = false,
  withPageList = true,
}) => {
  const pages = useMemo(() => {
    return generatePageRange(currentPage, totalPages)
  }, [currentPage, totalPages])

  const handleClickPage = useCallback(
    (page) => () => {
      if (onClick && page > 0 && page <= totalPages) onClick(page)
    },
    [onClick, totalPages]
  )

  const handleShowMore = useCallback(() => {
    if (onClick) onClick(currentPage + 1, true)
  }, [onClick, currentPage])

  return (
    <>
      {withShowMore && (
        <div className={styles.seeMore} onClick={handleShowMore}>
          ПОСМОТРИТЕ ЕЩЁ
        </div>
      )}
      <div className={styles.pagination}>
        <PageButton
          onClick={handleClickPage(currentPage - 1)}
          label="«"
          className={`${currentPage <= 1 ? styles.disabled : ''}`}
        />
        {withPageList &&
          (pages || []).map((page, key) => (
            <PageButton
              key={`${page}-${key}`}
              onClick={handleClickPage(page)}
              label={page}
              className={currentPage === page ? styles.currentPage : ''}
            />
          ))}
        <PageButton
          onClick={handleClickPage(currentPage + 1)}
          label="»"
          className={`${currentPage === totalPages ? styles.disabled : ''}`}
        />
      </div>
    </>
  )
}

export default Pagination

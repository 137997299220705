import React, { useEffect } from 'react'
import styles from './Message.module.scss'
import DoubleCheckIcon from '../../images/icons/DoubleCheckSvgIcon.svg'
import DoubleCheckBlueIcon from '../../images/icons/DoubleCheckBlueSvgIcon.svg'
import { getFormattedDate } from '../../utils/dateFotmater'

const Message = ({ uuid, sender, body, read, createdAt, isMyMessage, onMarkMsgRead }) => {
  useEffect(() => {
    let timeOut
    if (!isMyMessage && !read) {
      timeOut = setTimeout(() => {
        onMarkMsgRead(uuid)
      }, 2000)
    }
    return () => clearTimeout(timeOut)
  }, [isMyMessage, read, onMarkMsgRead, uuid])

  const CheckIcon = read ? DoubleCheckBlueIcon : DoubleCheckIcon
  return (
    <div
      id={uuid}
      className={`${styles.message} ${
        isMyMessage ? styles.question : styles.answer
      }`}
    >
      <div className={styles.msgSenderName}>
        {isMyMessage ? 'me': sender.firstname}
      </div>
      <div className={styles.msgDate}>
        <span>{getFormattedDate(createdAt)}</span>
          <CheckIcon className={styles.msgIcon} />
      </div>
      <div className={styles.msgText}>{body}</div>
    </div>
  )
}

export default Message

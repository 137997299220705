import React, { useCallback, useContext, useEffect, useRef } from 'react'
import styles from './MessengerPage.module.scss'
import Title from '../../components/Title'
import DeliveryIcon from '../../images/icons/DeliveryCarSvgIcon.svg'
import PoundIcon from '../../images/icons/PoundSvgIcon.svg'
import Message from '../../components/Message'
import SendIcon from '../../images/icons/SendSvgIcon.svg'
import { ApiContext } from '../../containers/ApiContainer'
import BuyerInfo from './BuyerInfo'
import TextButton from '../../components/TextButton'
import { SELL } from './MessengerPage'

const MessageContainer = ({ tab }) => {
  const {
    messages: { activeProduct, activeConversation, activeConversationUuid, listByUUID },
    user,
    sendMessages,
    readMessage,
    loadMessages,
    postOrder,
    deleteConversation,
  } = useContext(ApiContext)
  const msgRef = useRef()

  useEffect(() => {
    if (activeConversationUuid) loadMessages(activeConversationUuid)
  }, [activeConversationUuid, loadMessages])

  const handleMarkMsgRead = useCallback(
    (uuid) => {
      readMessage(uuid, tab, activeConversationUuid)
    },
    [tab, readMessage, activeConversationUuid]
  )

  const handleSendMsg = useCallback(() => {
    if (msgRef.current.value.length) {
      sendMessages(activeConversationUuid, {
        message: {
          senderUuid: user.uuid,
          senderFirstname: user.firstname,
          body: msgRef.current.value,
        },
      })
      msgRef.current.value = ''
    }
  }, [activeConversationUuid, sendMessages, user])

  const handleSellOrder = useCallback(() => {
    postOrder({ conversation_uuid: activeConversationUuid })
  }, [postOrder, activeConversationUuid])

  const handleDeleteConversation = useCallback(() => {
    deleteConversation(activeConversationUuid, tab)
  }, [deleteConversation, activeConversationUuid, tab])

  if (!activeConversationUuid || !activeProduct) return null

  return (
    <div className={styles.right}>
      <div className={styles.top}>
        <div className={styles.productName}>
          <Title
            className={styles.msgProductName}
            text={activeProduct.name}
            data-uuid={activeProduct.uuid}
          />
          <BuyerInfo tab={tab} />
        </div>
        <div className={styles.productStatus}>
          <div className={styles.textBtnWrapper}>
            {activeConversation?.order?.status?.id === 700 ? (
              <TextButton className={styles.sold} color="Red">
                Продано
              </TextButton>
            ) : (
              <>
                {tab === SELL && (
                  <TextButton className={styles.textBtn} onClick={handleSellOrder} color="Green">
                    Продать
                  </TextButton>
                )}
                <TextButton
                  className={styles.textBtn}
                  onClick={handleDeleteConversation}
                  color="Red"
                >
                  Удалить
                </TextButton>
              </>
            )}
          </div>
          <div>
            <div className={styles.productStatusItem}>
              <DeliveryIcon />
              <span>{activeProduct.delivery?.name}</span>
            </div>
            <div className={styles.productStatusItem}>
              <PoundIcon />
              <span>{activeProduct.status?.name}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.center}>
        {listByUUID[activeConversationUuid]?.map((msg) => {
          const { uuid, sender } = msg
          const isMyMessage = sender.uuid === user.uuid // TODO: check this on real data
          return (
            <Message
              {...msg}
              key={uuid}
              isMyMessage={isMyMessage}
              onMarkMsgRead={handleMarkMsgRead}
            />
          )
        })}
      </div>
      <div className={styles.bottom}>
        <textarea ref={msgRef} autoFocus placeholder="Введите текст" className={styles.textArea} />
        <SendIcon className={styles.saveBtn} onClick={handleSendMsg} />
      </div>
    </div>
  )
}

export default MessageContainer

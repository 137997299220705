import React, { useCallback, useContext, useEffect, useRef } from 'react'
import styles from './MessengerPage.module.scss'
import { ApiContext } from '../../containers/ApiContainer'

const SearchBlock = ({ tab }) => {
  const inputRef = useRef()
  const { handleSearchConversation } = useContext(ApiContext)

  const handleClearSearch = useCallback(() => {
    inputRef.current.value = ''
    handleSearchConversation(tab, '')
  }, [handleSearchConversation, tab])

  useEffect(() => {
    return handleClearSearch
  }, [handleClearSearch])

  const handleSearch = useCallback(() => {
    handleSearchConversation(tab, inputRef.current.value)
  }, [handleSearchConversation, tab])

  const handleEnter = useCallback(
    (e) => {
      if (e.key === 'Enter') handleSearch()
    },
    [handleSearch]
  )

  return (
    <div className={styles.searchBlock}>
      <div className={styles.searchIcon} />
      <div className={styles.inputWrapper}>
        <input className={styles.searchInput} ref={inputRef} onKeyDown={handleEnter} />
        <span className={styles.removeSearchTextBtn} onClick={handleClearSearch} />
      </div>
      <span className={styles.searchBtn} onClick={handleSearch}>
        Поиск
      </span>
    </div>
  )
}

export default SearchBlock

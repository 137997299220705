import React, { useCallback, useContext, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useMedia, useClickAway } from 'react-use'
import styles from './Header.module.scss'
import logoImg from '../../images/logo.png'
import Notification from '../Notification'
import {
  EXTRA_LARGE_SCREEN_SIZE,
  FAVORITES_PAGE,
  FAVORITES_PAGE_TITLE,
  HOME_PAGE,
  MEDIUM_SCREEN_SIZE,
  MESSENGER_PAGE,
  MESSENGER_PAGE_TITLE,
  TABLET_SCREEN_SIZE,
} from '../../constants'
import { ApiContext } from '../../containers/ApiContainer'
import BurgerSvgIcon from '../../images/icons/BurgerSvgIcon.svg'
import BurgerCloseSvgIcon from '../../images/icons/BurgerCloseSvgIcon.svg'
import Modal from '../Modal'
import AuthForm from '../AuthForm'
import { useUserIsAdmin } from '../../hooks/useUserIsAdmin'
import {
  MenuItem,
  MyAdsPageNavLink,
  MyOrdersPageNavLink,
  NewAdvertPageNavLink,
  NewAdvertsModerationPageNavLink,
  RulePageNavLink,
} from '../Menu'
import { SocialLinks } from '../SocialBlock'
import { useSearchBar } from '../../hooks/useSearchBar'

const mobileTogglerId = 'm-menu-toggler'

const ATag = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
)

const Header = ({ className }) => {
  const {
    user,
    wishList,
    messages: { buyingCount, sellingCount },
  } = useContext(ApiContext)
  const mobileMenuRef = useRef()
  const isExtraLargeScreenSize = useMedia(EXTRA_LARGE_SCREEN_SIZE)
  const isMediumScreenSize = useMedia(MEDIUM_SCREEN_SIZE)
  const isMobileScreenSize = useMedia(TABLET_SCREEN_SIZE)
  const isAdmin = useUserIsAdmin()
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const { toggleSearch } = useSearchBar()
  const [isShowAuthModal, setIsShowAuthModal] = useState(false)
  useClickAway(mobileMenuRef, (e) => e.target?.id !== mobileTogglerId && setShowMobileMenu(false))

  const onCloseAuthModal = useCallback(() => setIsShowAuthModal(false), [])

  const handleToggleMenu = useCallback(() => {
    setShowMobileMenu((p) => !p)
  }, [])

  const handleHideMobileMenu = useCallback(() => {
    setShowMobileMenu(false)
  }, [])

  const notificationButtons = [
    {
      title: FAVORITES_PAGE_TITLE,
      showTitle: !isMediumScreenSize,
      iconClass: 'heartIcon',
      count: wishList.count,
      href: FAVORITES_PAGE,
      onClick: handleHideMobileMenu,
    },
    {
      title: MESSENGER_PAGE_TITLE,
      showTitle: !isMediumScreenSize,
      iconClass: 'messageIcon',
      count: buyingCount + sellingCount,
      href: MESSENGER_PAGE,
      onClick: handleHideMobileMenu,
    },
  ]

  const SEARCH_MENU_ITEM = (
    <MenuItem onClick={toggleSearch}>
      <Link to={HOME_PAGE} className={styles.searchLink}>
        <span className={styles.search} />
        {!isMobileScreenSize && ' Поиск'}
      </Link>
    </MenuItem>
  )

  const SIGN_IN_MENU_ITEM = (
    <MenuItem onClick={() => setIsShowAuthModal(true)}>
      {isMobileScreenSize && <span className={styles.login} />}
      {!isMobileScreenSize &&
        (user ? (
          <div className={styles.userName}>{user.firstname}</div>
        ) : (
          <>
            <span className={styles.login} />
            Войти
          </>
        ))}
    </MenuItem>
  )

  const MobileMenuBtn = showMobileMenu ? BurgerCloseSvgIcon : BurgerSvgIcon

  return (
    <>
      {isShowAuthModal && (
        <Modal onClose={onCloseAuthModal}>
          <AuthForm onFinish={onCloseAuthModal} />
        </Modal>
      )}
      <header className={`${className} ${styles.header}`}>
        <div className={styles.headerContent}>
          <Link to={HOME_PAGE}>
            <img src={logoImg} className={styles.logo} alt="bookashka" title="bookashka" />
          </Link>
          <div className={styles.menu}>
            {!isMobileScreenSize && <RulePageNavLink />}
            <MyAdsPageNavLink withIcon hideText={isMobileScreenSize || isMediumScreenSize} />
            <MyOrdersPageNavLink withIcon hideText={isMobileScreenSize || isMediumScreenSize} />
            {notificationButtons.map((el, index) => (
              <Notification key={index} {...el} />
            ))}
            <NewAdvertPageNavLink withIcon hideText={isMobileScreenSize || isMediumScreenSize} />
            {isAdmin && (
              <NewAdvertsModerationPageNavLink withIcon hideText={isMobileScreenSize || isMediumScreenSize} />
            )}
            {isMobileScreenSize && [SEARCH_MENU_ITEM, SIGN_IN_MENU_ITEM]}
          </div>
          <div className={styles.actionMenu}>
            {!isMobileScreenSize && (
              <>
                {SEARCH_MENU_ITEM}
                {isExtraLargeScreenSize && (
                  <>
                    <ATag href="https://www.facebook.com/Bookashkashop">
                      <span className={styles.facebook} />
                    </ATag>
                    <ATag href="https://www.instagram.com/Bookashkashop">
                      <span className={styles.instagram} />
                    </ATag>
                  </>
                )}
                {SIGN_IN_MENU_ITEM}
              </>
            )}
            {isMobileScreenSize && (
              <MobileMenuBtn
                className={styles.mobileMenuButton}
                id={mobileTogglerId}
                onClick={handleToggleMenu}
              />
            )}
          </div>
        </div>
        {showMobileMenu && (
          <div ref={mobileMenuRef} className={styles.mobileMenu}>
            <RulePageNavLink onClick={handleHideMobileMenu} />
            <MyAdsPageNavLink onClick={handleHideMobileMenu} />
            <MyOrdersPageNavLink onClick={handleHideMobileMenu} />
            {notificationButtons.map((el, index) => (
              <Notification key={index} {...el} showTitle showIcon={false} />
            ))}
            <div className={styles.divider} />
            <SocialLinks className={styles.socialLink} />
          </div>
        )}
      </header>
    </>
  )
}

export default Header

import React, { useMemo } from 'react'
import styles from './Counter.module.scss'

const Counter = ({ count = 0, yellow = false, green = false, gray = false, className = '' }) => {
  const color = useMemo(() => {
    if (yellow) return styles.yellow
    if (green) return styles.green
    if (gray) return styles.gray
    return count > 0 ? styles.yellow : styles.green
  }, [count, green, yellow, gray])

  return <span className={`${styles.counter} ${color} ${className}`}>{count}</span>
}

export default Counter

import React from 'react'
import { useMedia } from 'react-use'

import styles from './Footer.module.scss'
import logoImg from '../../images/logo.png'
import Menu from '../Menu'
import { BOOKASHKA_HOME_PAGE, MOBILE_SCREEN_SIZE } from '../../constants'
import { MobileSocialBlock, SocialBlock } from '../SocialBlock'

const Footer = ({ className }) => {
  const isMobileScreenSize = useMedia(MOBILE_SCREEN_SIZE)
  const SocialComponent = isMobileScreenSize ? MobileSocialBlock : SocialBlock
  return (
    <footer className={`${className} ${styles.footer}`}>
      <div className={styles.footerContent}>
        <div className={styles.footerWrapper}>
          <a
            className={styles.logo}
            target="_blank"
            href={BOOKASHKA_HOME_PAGE}
            rel="noopener noreferrer"
          >
            <img src={logoImg} alt="logo" title="logo" />
          </a>
          <Menu
            className={styles.sections}
            linkClassName={styles.menuLink}
            selectedMenuClassName={styles.selectedMenu}
          />
          <SocialComponent />
        </div>
        <div className={styles.disclaimer}>
          Copyright © 2022 Bookashka.co.uk | Детские книги и настольные игры
        </div>
      </div>
    </footer>
  )
}

export default Footer

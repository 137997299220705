import React from 'react'
import styles from './GridContainerBlock.module.scss'
import bottomBannerImg from '../../images/banners/b-bottom-1920.png'
import bottomBannerMobileImg from '../../images/banners/b-bottom-512.png'
import { BOOKASHKA_HOME_PAGE, MOBILE_SCREEN_SIZE } from '../../constants'
import { useMedia } from 'react-use'

const GridContainerBlock = ({ className = '', children, withBottomBanner = false }) => {
  const isMobileScreenSize = useMedia(MOBILE_SCREEN_SIZE)
  return (
    <div className={`${styles.container} ${className}`}>
      {children}
      {withBottomBanner && (
        <div className={styles.bannerBottom}>
          <a target="_blank" href={BOOKASHKA_HOME_PAGE} rel="noopener noreferrer">
            <img
              src={isMobileScreenSize ? bottomBannerMobileImg : bottomBannerImg}
              alt="bottom-banner"
              title="bottom-banner"
            />
          </a>
        </div>
      )}
    </div>
  )
}

export default GridContainerBlock

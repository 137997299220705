import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react'
import _debounce from 'lodash/debounce'
import { useHistory } from 'react-router-dom'

import Adv from '../../components/Adv'
import ArrowDown from '../../images/icons/ArrowDownSvgIcon.svg'
import topBannerImg from '../../images/banners/b-top-1920.png'
import topBannerImgMobile from '../../images/banners/b-top-512.png'
import styles from './HomePage.module.scss'
import Pagination from '../../components/Pagination'
import ContentBlock from '../../components/ContentBlock'
import GridContainerBlock from '../../components/GridContainerBlock'
import FiltersBlock from '../../components/FiltersBlock'
import { ApiContext } from '../../containers/ApiContainer'
import Select from '../../components/Select'
import {
  DESTINATIONS_LIST,
  NEW_ADVERT_PAGE,
  SORT_BY,
  TABLET_SCREEN_SIZE,
} from '../../constants'
import Input from '../../components/Input'
import FormItem from '../../components/FormItem'
import Form from '../../components/Form'
import { useForm } from '../../hooks/useForm'
import Button from '../../components/Button'
import SpinnerBlock from '../../components/SpinnerBlock'
import { useMedia } from 'react-use'
import { useSearchBar } from '../../hooks/useSearchBar'

const selectStyle = { borderBottom: 'unset', padding: 0, minHeight: '24px' }
export const dropdownHandleRenderer = ({ state: { dropdown } }) => {
  return (
    <ArrowDown
      className={styles.arrowDown}
      style={{ transform: `rotate(${dropdown ? 180 : 0}deg)` }}
    />
  )
}

const HomePage = () => {
  const {
    user,
    loadHomeProductsList,
    loadCategories,
    homeProducts: { products, summary },
    categories,
    isLoading,
    getUserStats,
  } = useContext(ApiContext)
  const history = useHistory()
  const form = useForm()
  const isMobileScreenSize = useMedia(TABLET_SCREEN_SIZE)
  const debouncedLoadHomeProductsList = useMemo(() => _debounce(loadHomeProductsList, 400), [loadHomeProductsList])
  const { isSearchOpened } = useSearchBar()

  const [params, setParams] = useState({
    perPage: 16,
    page: 1,
    isShowMore: false,
    sort: 'newest',
    term: '',
    distance: null,
  })

  const filteredCategories = useMemo(() => {
    return summary?.categories && params.term
      ? categories.filter((cat) => summary?.categories.some((c) => c.id === cat.id))
      : categories
  }, [categories, summary, params])

  useEffect(() => {
    if (user) {
      getUserStats()
    }

    loadCategories()
  }, [user, loadCategories, getUserStats])

  useEffect(() => {
    debouncedLoadHomeProductsList(params)
  }, [debouncedLoadHomeProductsList, params])

  const handleNewAdvert = useCallback(() => {
    history.push(NEW_ADVERT_PAGE)
  }, [history])

  const handlePageClick = useCallback((page, isShowMore) => {
    setParams((s) => ({ ...s, page, isShowMore }))
  }, [])

  const handleSort = useCallback(([{ id }]) => {
    setParams((s) => ({ ...s, sort: id }))
  }, [])

  const handleChangeCategory = useCallback((value) => {
    const [{ id }] = !!value.length ? value : [{ id: undefined }]
    setParams((s) => ({ ...s, categoryId: id }))
  }, [])

  const handleSearchCategory = useCallback(
    ({ state }) => {
      loadCategories(state.search)
    },
    [loadCategories]
)

  const handleChangeTerm = useCallback((term) => {
    setParams((s) => ({ ...s, term }))
  }, [])

  const handleChangeDistance = useCallback(([{ id }]) => {
    setParams((s) => ({ ...s, distance: id }))
  }, [])

  const categorySelectValue =
    params.categoryId && categories.length
      ? [categories.find(({ id }) => id === params.categoryId)]
      : []
  const sortSelectValue = [SORT_BY.find(({ id }) => id === params.sort)]

  const PLACE_AN_AD_BTN = (
    <Button
      onClick={handleNewAdvert}
      className={isMobileScreenSize ? styles.newAdvertBtnMobile : styles.newAdvertBtn}
    >
      РАЗМЕСТИТЬ ОБЪЯВЛЕНИЕ
    </Button>
  )

  return (
    <GridContainerBlock withBottomBanner>
      {(!isMobileScreenSize || (isMobileScreenSize && isSearchOpened)) && (
        <ContentBlock noMargin>
          {/* <SearchBar /> */}
          <Form form={form}>
            <div className={styles.searchBar}>
              <FormItem justUI noLabel formItemCN={styles.searchBarFormItem}>
                <Select
                  options={categories}
                  placeholder="Все категории"
                  className={styles.searchBarSelect}
                  value={categorySelectValue}
                  onChange={handleChangeCategory}
                  // searchFn={handleSearchCategory}
                />
              </FormItem>
              <FormItem justUI noLabel formItemCN={styles.searchBarFormItem}>
                <Input
                  placeholder="Введите название"
                  inputCN={styles.searchBarInput}
                  value={params.term}
                  autoFocus
                  onChange={handleChangeTerm}
                />
              </FormItem>
              <FormItem justUI noLabel formItemCN={styles.searchBarFormItem}>
                <Select
                  clearable={false}
                  searchable={false}
                  options={DESTINATIONS_LIST}
                  placeholder="Расстояние"
                  value={params.distance}
                  onChange={handleChangeDistance}
                  className={styles.searchBarSelect}
                />
              </FormItem>
            </div>
          </Form>
        </ContentBlock>
      )}

      <div className={styles.bannerTop}>
        <img
          src={isMobileScreenSize ? topBannerImgMobile : topBannerImg}
          alt="top-banner"
          title="top-banner"
        />
        {!isMobileScreenSize && PLACE_AN_AD_BTN}
      </div>
      {isMobileScreenSize && PLACE_AN_AD_BTN}
      <ContentBlock>
        <FiltersBlock>
          <div className={styles.categories}>
            <Select
              className={styles.select}
              style={selectStyle}
              options={filteredCategories}
              values={categorySelectValue}
              onChange={handleChangeCategory}
              placeholder="Категории"
              clearable={false}
              searchable={false}
              dropdownHandleRenderer={dropdownHandleRenderer}
            />
          </div>
          <div className={styles.sortBy}>
            <Select
              className={styles.select}
              style={selectStyle}
              options={SORT_BY}
              values={sortSelectValue}
              onChange={handleSort}
              clearable={false}
              searchable={false}
              dropdownHandleRenderer={dropdownHandleRenderer}
            />
          </div>
        </FiltersBlock>
        {isLoading ? (
          <SpinnerBlock />
        ) : (
          <>
            <div className={styles.advs}>
              {products.map((product) => (
                <Adv key={product.uuid} {...product} actionButtons />
              ))}
            </div>
            {summary.pages > 1 && (
              <Pagination
                currentPage={summary.page}
                totalPages={summary.pages}
                onClick={handlePageClick}
                withShowMore
              />
            )}
          </>
        )}
      </ContentBlock>
    </GridContainerBlock>
  )
}

export default HomePage

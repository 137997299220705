import { fromBlob } from 'image-resize-compress'

const isMoreThan1Mb = (bytes) => bytes > 1_000_000

export const needsToBeReduced = (file) => isMoreThan1Mb(file.size)

export const reduceImageSize = async (blobFile) => {
  // quality value for webp and jpeg formats.
  const quality = 50
  // output width. 0 will keep its original width and 'auto' will calculate its scale from height.
  const width = 800
  // output height. 0 will keep its original height and 'auto' will calculate its scale from width.
  const height = null
  // file format: png, jpeg, bmp, gif, webp. If null, original format will be used.
  const format = null

  // note only the blobFile argument is required
  const newBlob = await fromBlob(blobFile, quality, width, height, format)

  return new File([newBlob], blobFile.name, {
    type: blobFile.type,
    lastModified: new Date().getTime(),
  })
}

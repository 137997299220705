import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RouteWrapper from './RouteLayout'
import MainLayout from './layouts/Main'
import HomePage from '../pages/HomePage'
import AdvCardPage from '../pages/AdvCardPage'
import FavoritesPage from '../pages/FavoritesPage'
import MyAdsPage from '../pages/MyAdsPage'
import OrdersPage from '../pages/OrdersPage'
import NewAdvertPage from '../pages/NewAdvertPage'
import RulesPage from '../pages/RulesPage'
import ProfilePage from '../pages/ProfilePage'
import PwdResetPage from '../pages/PwdResetPage'
import PwdResetRequestPage from '../pages/PwdResetRequestPage'
import MessengerPage from '../pages/MessengerPage'
import {
  ADV_CARD_PAGE,
  EDIT_ADVERT_PAGE,
  FAVORITES_PAGE,
  FAVORITES_PAGE_TITLE,
  HOME_PAGE,
  MESSENGER_PAGE,
  MESSENGER_PAGE_TITLE,
  MY_ADS_PAGE,
  MY_ADS_PAGE_TITLE,
  MY_ORDERS_PAGE,
  MY_ORDERS_PAGE_TITLE,
  NEW_ADVERT_PAGE,
  NEW_ADVERTS_MODERATION_PAGE,
  RULES_PAGE,
  RULES_PAGE_TITLE,
  PROFILE_PAGE,
  PWD_RESET_PAGE,
  PWD_RESET_REQUEST_PAGE,
} from '../constants'
import NewAdvertModerationPage from '../pages/NewAdvertModerationPage'

export default () => {
  return (
    <Switch>
      <RouteWrapper
        exact
        path={HOME_PAGE}
        component={HomePage}
        layout={MainLayout}
        title="Доска объявлений"
      />
      <RouteWrapper
        exact
        path={ADV_CARD_PAGE}
        component={AdvCardPage}
        layout={MainLayout}
        title="Карточка товара"
      />
      <RouteWrapper
        exact
        path={FAVORITES_PAGE}
        component={FavoritesPage}
        layout={MainLayout}
        title={FAVORITES_PAGE_TITLE}
      />
      <RouteWrapper
        exact
        path={NEW_ADVERT_PAGE}
        component={NewAdvertPage}
        layout={MainLayout}
        title="Новое объявление"
      />
      <RouteWrapper
        exact
        path={EDIT_ADVERT_PAGE}
        component={NewAdvertPage}
        layout={MainLayout}
        title="Редактировать объявление"
      />
      <RouteWrapper
        exact
        path={MY_ADS_PAGE}
        component={MyAdsPage}
        layout={MainLayout}
        title={MY_ADS_PAGE_TITLE}
      />
      <RouteWrapper
        exact
        path={MY_ORDERS_PAGE}
        component={OrdersPage}
        layout={MainLayout}
        title={MY_ORDERS_PAGE_TITLE}
      />
      <RouteWrapper
        exact
        path={RULES_PAGE}
        component={RulesPage}
        layout={MainLayout}
        title={RULES_PAGE_TITLE}
      />
      <RouteWrapper
        exact
        path={PROFILE_PAGE}
        component={ProfilePage}
        layout={MainLayout}
        title="Профиль"
      />
      <RouteWrapper
        exact
        path={PWD_RESET_PAGE}
        component={PwdResetPage}
        layout={MainLayout}
        title="Сменить Пароль"
      />
      <RouteWrapper
        exact
        path={PWD_RESET_REQUEST_PAGE}
        component={PwdResetRequestPage}
        layout={MainLayout}
        title="Восстановление Пароля"
      />
      <RouteWrapper
        exact
        path={MESSENGER_PAGE}
        component={MessengerPage}
        layout={MainLayout}
        title={MESSENGER_PAGE_TITLE}
      />
      <RouteWrapper
        exact
        isPrivate
        path={NEW_ADVERTS_MODERATION_PAGE}
        component={NewAdvertModerationPage}
        layout={MainLayout}
        title="Модерирование"
      />
      <Redirect to="/" />
    </Switch>
  )
}

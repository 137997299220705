import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styles from './HTMLEditor.module.scss'

const HTMLEditor = ({ value, onChange }) => {
  return <ReactQuill value={value} className={styles.editor} onChange={onChange} />
}

export default HTMLEditor

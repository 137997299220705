import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import styles from './MessengerPage.module.scss'
import Counter from '../../components/Counter'
import { ApiContext } from '../../containers/ApiContainer'
import { useEventHandlerById, useSetActiveConversation } from './hooks'
import Conversation from './Conversation'
import { useQueryParams } from '../../hooks/useQueryParams'
import SearchBlock from './SearchBlock'
import { SELL } from './MessengerPage'

const ProductListWithConversations = ({ onSelect }) => {
  const {
    messages,
    setActiveProduct,
    setActiveConversationUuid,
    loadMessagesByProductIdAndBuyerId,
  } = useContext(ApiContext)
  const { sellingUuids, sellingMap, activeConversationUuid: storeActiveConversationUuid } = messages
  const { productUuid, tab, buyerUuid } = useQueryParams()
  const [
    activeConversationUuid,
    handleChangeActiveConversation,
    setLocalActiveConversationUuid,
  ] = useEventHandlerById()

  const unselectActiveConversation = useCallback(() => {
    setActiveConversationUuid(undefined)
    setLocalActiveConversationUuid(undefined)
  }, [setActiveConversationUuid, setLocalActiveConversationUuid])

  const [activeProductUuid, handleChangeActiveProduct, setActiveProductUuid] = useEventHandlerById(
    unselectActiveConversation
  )

  const activeConversation = useMemo(() => {
    const productUuid = sellingUuids.find((uuid) => uuid === activeProductUuid)
    if (productUuid) {
      return sellingMap[productUuid].conversations.find(
        ({ uuid }) => uuid === activeConversationUuid
      )
    }
  }, [activeConversationUuid, activeProductUuid, sellingMap, sellingUuids])
  useSetActiveConversation(activeConversationUuid, activeConversation)

  // active first message
  useEffect(() => {
    const uuid = sellingUuids.length && sellingUuids[0]
    uuid && setActiveProductUuid(uuid)
  }, [sellingUuids, setActiveProductUuid])

  useEffect(() => {
    const cuuid = activeProductUuid && sellingMap[activeProductUuid]?.conversations[0]?.uuid
    cuuid && setActiveConversationUuid(cuuid)
  }, [activeProductUuid, setActiveConversationUuid, sellingMap])

  useEffect(() => {
    if (storeActiveConversationUuid) {
      // we need to set locally uuid for activeConversation from store when we got productUuid in queryParam
      // for highlight active conversation in the list
      setLocalActiveConversationUuid(storeActiveConversationUuid)
    }
  }, [setLocalActiveConversationUuid, storeActiveConversationUuid])

  useEffect(() => {
    if (productUuid && tab === SELL) {
      // load only for first time when page has been opened
      loadMessagesByProductIdAndBuyerId({ aProductUuid: productUuid, buyerUuid, type: 'selling2' })
    }

    return () => {
      setActiveProduct(undefined)
      setActiveConversationUuid(undefined)
    }
  }, [productUuid, buyerUuid]) // eslint-disable-line

  useEffect(() => {
    if (productUuid && tab === SELL && sellingUuids.length) {
      // if productUuid is present in queryParam
      // and selling list has been loaded we need to set activeProduct
      setActiveProductUuid(productUuid)
    }
  }, [productUuid, sellingUuids.length, setActiveProductUuid, tab])

  useEffect(() => {
    if (activeProductUuid) {
      // set on each time when user click on new product in product list
      const productUuid = sellingUuids.find((uuid) => uuid === activeProductUuid)
      setActiveProduct(sellingMap[productUuid])
    }
  }, [setActiveProduct, activeProductUuid, sellingUuids, sellingMap])

  return (
    <div className={styles.left}>
      <SearchBlock tab={SELL} />
      <div className={styles.conversationsList}>
        {sellingUuids?.map((uuid) => {
          const { mainImage, name, totalUnReadMsgs = 0 } = sellingMap[uuid]
          return (
            <>
              <div
                key={uuid}
                id={uuid}
                onClick={handleChangeActiveProduct}
                className={`${styles.conversation} ${
                  uuid === activeProductUuid ? styles.selected : ''
                }`}
              >
                <div>
                  <img className={styles.advImage} src={mainImage?.url} alt={name} title={name} />
                </div>
                <div className={styles.col2}>
                  <div className={styles.productName}>{name}</div>
                </div>
                <div className={styles.col3}>
                  <div className={styles.maT20}>
                    <Counter className={styles.unreadCount} count={totalUnReadMsgs} />
                  </div>
                </div>
              </div>
              {activeProductUuid &&
                uuid === activeProductUuid &&
                sellingMap[activeProductUuid]?.conversations.map((conversation) => (
                  <Conversation
                    {...conversation}
                    key={conversation.uuid}
                    isActive={conversation.uuid === activeConversationUuid}
                    onClick={(e) => {
                      onSelect()
                      handleChangeActiveConversation(e)
                    }}
                  />
                ))}
            </>
          )
        })}
      </div>
    </div>
  )
}

export default ProductListWithConversations

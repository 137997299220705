import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ContentBlock from '../../components/ContentBlock'
import Adv from '../../components/Adv'
import GridContainerBlock from '../../components/GridContainerBlock'
import styles from './FavoritesPage.module.scss'
import PageBackTitle from '../../components/PageBackTitle'
import RowForAdv from '../../components/RowForAdv'
import TextButton from '../../components/TextButton'
import Title from '../../components/Title'
import AdvRowBlock from '../../components/AdvRowBlock'
import AdvImage from '../../components/AdvImage'
import AdvInfoBlock from '../../components/AdvInfoBlock'
import DeleteActionBlock from '../../components/DeleteActionBlock'
import Pagination from '../../components/Pagination'
import { ApiContext } from '../../containers/ApiContainer'
import { MessageButton } from '../../components/Button'
import { HOME_PAGE, LOADING_MESSAGE } from '../../constants'
import { getFormattedDate } from '../../utils/dateFotmater'
import { showPleaseLogInError } from '../../utils/showPleaseLogInError'

const FavoritesPage = () => {
  const {
    user,
    loadWishList,
    wishList: { products, summary, loaded },
    getUserStats,
  } = useContext(ApiContext)

  const history = useHistory()

  useEffect(() => {
    if (user) {
      loadWishList()
      getUserStats()
    } else {
      showPleaseLogInError()
      history.push(HOME_PAGE)
    }
  }, [user, history, loadWishList, getUserStats])

  return (
    <GridContainerBlock>
      <ContentBlock noMargin>
        <PageBackTitle title="избранное" />
      </ContentBlock>
      {!products?.length && (
        <RowForAdv>
          <ContentBlock noMargin>
            {!loaded ? LOADING_MESSAGE : 'Пока еще ничего не добавлено'}
          </ContentBlock>
        </RowForAdv>
      )}
      {products.map(({ seller, ...adv }) => (
        <RowForAdv key={adv.uuid}>
          <ContentBlock noMargin>
            <AdvRowBlock>
              <AdvImage>
                <Adv {...adv} />
              </AdvImage>
              <AdvInfoBlock>
                <Title text={adv.name} data-uuid={adv.uuid} />
                <div>
                  {!adv?.publishedAt && (
                    <div>Опубликовано:&nbsp;{getFormattedDate(adv?.publishedAt)}</div>
                  )}
                  {adv?.author && <div>Автор: {adv.author}</div>}
                </div>
                <div>
                  <div>Продавец:&nbsp;{seller?.firstname}</div>
                  <div>Зарегистрирован:&nbsp;{getFormattedDate(seller?.dateAdded)}</div>
                  <div>{seller?.address?.city}</div>
                </div>
              </AdvInfoBlock>
              <DeleteActionBlock className={styles.delAction}>
                <MessageButton productUuid={adv.uuid} />
                <TextButton color="Red">Удалить</TextButton>
              </DeleteActionBlock>
            </AdvRowBlock>
          </ContentBlock>
        </RowForAdv>
      ))}
      {summary?.pages > 1 && (
        <RowForAdv>
          <ContentBlock noMargin>
            <Pagination currentPage={summary.page} totalPages={summary.pages} />
          </ContentBlock>
        </RowForAdv>
      )}
    </GridContainerBlock>
  )
}

export default FavoritesPage

import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './Notification.module.scss'
import menuStyles from '../Menu/Menu.module.scss'
import Counter from '../Counter'

const Notification = ({ onClick, iconClass, count, title, href, showTitle, showIcon = true }) => {
  return (
    <NavLink
      to={href}
      onClick={onClick}
      className={styles.menuLink}
      activeClassName={menuStyles.selectedMenu}
      alt={title}
      title={title}
    >
      {showIcon && (
        <span className={`${styles.icon} ${styles[iconClass]}`}>
          <span className={styles.counterBlock}>
            <Counter count={count} />
          </span>
        </span>
      )}
      {showTitle && <span>{title}</span>}
    </NavLink>
  )
}

export default Notification

import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { MESSENGER_PAGE } from '../constants'
import { BUY, SELL } from '../pages/MessengerPage'
import { createQueryStringFromObject } from '../utils/createQueryStringFromObject'

export const useGoToMessengerPage = () => {
  const history = useHistory()

  const openSellTab = useCallback(
    (productUuid, buyerUuid) => {
      const query = createQueryStringFromObject({ tab: SELL, productUuid, buyerUuid }, false)
      return history.push(MESSENGER_PAGE + query)
    },
    [history]
  )

  const openBuyTab = useCallback(
    (productUuid) => {
      const query = createQueryStringFromObject({ tab: BUY, productUuid }, false)
      return history.push(MESSENGER_PAGE + query)
    },
    [history]
  )

  return { openSellTab, openBuyTab }
}

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ApiContext } from '../../containers/ApiContainer'
import TextButton from '../../components/TextButton'
import GridContainerBlock from '../../components/GridContainerBlock'
import ContentBlock from '../../components/ContentBlock'
import PageBackTitle from '../../components/PageBackTitle'
import RowForAdv from '../../components/RowForAdv'
import AdvRowBlock from '../../components/AdvRowBlock'
import AdvImage from '../../components/AdvImage'
import Adv from '../../components/Adv'
import AdvInfoBlock from '../../components/AdvInfoBlock'
import Title from '../../components/Title'
import DeleteActionBlock from '../../components/DeleteActionBlock'
import Pagination from '../../components/Pagination'
import styles from '../HomePage/HomePage.module.scss'
import Select from '../../components/Select'
import { HOME_PAGE, MOBILE_SCREEN_SIZE, ORDER_STATUSES, LOADING_MESSAGE } from '../../constants'
import { dropdownHandleRenderer } from '../HomePage'
import { getFormattedDate } from '../../utils/dateFotmater'
import { showPleaseLogInError } from '../../utils/showPleaseLogInError'
import { useMedia } from 'react-use'
import { useGoToMessengerPage } from '../../hooks/useGoToMessengerPage'

const OrdersPage = () => {
  const history = useHistory()
  const { openBuyTab } = useGoToMessengerPage()
  const { loadOrders, updateOrders, user, orders, getUserStats } = useContext(ApiContext)
  const { orders: ordersList, summary, loaded } = orders
  const [selectedFilter, setSelectedFilter] = useState('completed')
  const isMobileScreenSize = useMedia(MOBILE_SCREEN_SIZE)

  const selectStyle = useMemo(
    () => ({
      borderBottom: 'unset',
      padding: 0,
      minHeight: '24px',
      width: isMobileScreenSize ? 'fit-content' : 250,
    }),
    [isMobileScreenSize]
  )

  useEffect(() => {
    if (user) {
      loadOrders(selectedFilter)
      getUserStats()
    } else {
      showPleaseLogInError()
      history.push(HOME_PAGE)
    }
  }, [user, history, loadOrders, selectedFilter, getUserStats])

  const handleActionClick = useCallback(
    (e) => {
      const { status, uuid, productUuid } = e.target.dataset
      if (status === 'msg') {
        return openBuyTab(productUuid)
      }
      updateOrders(uuid, status, () => loadOrders(selectedFilter))
    },
    [openBuyTab, selectedFilter, loadOrders, updateOrders]
  )

  const renderActionBlock = (status, uuid, adv) => {
    switch (status) {
      case 'completed':
        return (
          <>
            <TextButton
              onClick={handleActionClick}
              data-status="archived"
              data-uuid={uuid}
              color="Green"
            >
              архивировать
            </TextButton>
            <TextButton
              onClick={handleActionClick}
              data-status="msg"
              data-product-uuid={adv.uuid}
              data-uuid={uuid}
              color="Blue"
            >
              связаться с продавцом
            </TextButton>
          </>
        )
      case 'reserved':
        return (
          <>
            <TextButton
              onClick={handleActionClick}
              data-status="cancelled"
              data-uuid={uuid}
              color="Red"
            >
              отменить
            </TextButton>
            <TextButton
              onClick={handleActionClick}
              data-status="msg"
              data-product-uuid={adv.uuid}
              data-uuid={uuid}
              color="Blue"
            >
              связаться с продавцом
            </TextButton>
          </>
        )
      case 'cancelled':
        return (
          <>
            <TextButton
              onClick={handleActionClick}
              data-status="deleted"
              data-uuid={uuid}
              color="Red"
            >
              Удалить
            </TextButton>
            <TextButton
              onClick={handleActionClick}
              data-status="msg"
              data-product-uuid={adv.uuid}
              data-uuid={uuid}
              color="Blue"
            >
              связаться с продавцом
            </TextButton>
          </>
        )
      case 'inactive':
        return (
          <>
            <TextButton
              onClick={handleActionClick}
              data-status="msg"
              data-product-uuid={adv.uuid}
              data-uuid={uuid}
              color="Blue"
            >
              связаться с продавцом
            </TextButton>
          </>
        )
      case 'archived':
      default:
        return ''
    }
  }

  const handleChangeFilter = useCallback(([{ filterValue }]) => {
    setSelectedFilter(filterValue)
  }, [])

  const selectFilterValue = [
    ORDER_STATUSES.find(({ filterValue }) => filterValue === selectedFilter),
  ]

  return (
    <GridContainerBlock>
      <ContentBlock noMargin>
        <PageBackTitle title="мои заказы" />
      </ContentBlock>
      <ContentBlock noMargin>
        <Select
          className={styles.select}
          style={selectStyle}
          options={ORDER_STATUSES}
          values={selectFilterValue}
          clearable={false}
          searchable={false}
          onChange={handleChangeFilter}
          dropdownHandleRenderer={dropdownHandleRenderer}
        />
      </ContentBlock>
      {!ordersList?.length && (
        <RowForAdv>
          <ContentBlock noMargin>
            {!loaded ? LOADING_MESSAGE : 'Пока еще ничего не заказали'}
          </ContentBlock>
        </RowForAdv>
      )}
      {ordersList.map(({ status, uuid, createdAt, product: adv }) => (
        <RowForAdv key={uuid}>
          <ContentBlock noMargin>
            <AdvRowBlock>
              <AdvImage>
                <Adv {...adv} />
              </AdvImage>
              <AdvInfoBlock>
                <Title text={adv.name} data-uuid={adv.uuid} />
                <div>
                  <div>Сформирован: {getFormattedDate(createdAt)}</div>
                  {adv?.author && <div>Автор: {adv.author}</div>}
                </div>
                <div>
                  <div>
                    Статус: <strong>{status.name}</strong>
                  </div>
                </div>
              </AdvInfoBlock>
              <DeleteActionBlock>{renderActionBlock(status.key, uuid, adv)}</DeleteActionBlock>
            </AdvRowBlock>
          </ContentBlock>
        </RowForAdv>
      ))}
      {summary?.pages > 1 && (
        <RowForAdv>
          <ContentBlock noMargin>
            <Pagination totalPages={summary.pages} currentPage={summary.page} />
          </ContentBlock>
        </RowForAdv>
      )}
    </GridContainerBlock>
  )
}

export default OrdersPage
